import { type FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../Table';
import { Card } from '../Card';
import { useLeaderboardData } from './hooks/useLeaderboardData';
import { LabelledToggle } from '../Toggle';
import styled from 'styled-components';
import { type LeaderboardPagedResults, useLeaderboardQuery } from '../../apiHooks/useLeaderboardQuery';
import type { LeaderboardUser } from '../../lib/types/LeaderboardUser';

export const LeaderboardUI: FC = () => {
  const params = useParams();
  const { placeholderData, columns, scopeFilterLabels, dateFilterLabels } = useLeaderboardData();
  const [selectedScopeFilter, setSelectedScopeFilter] = useState<string | undefined>(scopeFilterLabels[0]?.value);

  const { data: leaderboardData } = useLeaderboardQuery({
    type: selectedScopeFilter || 'company',
    mode: 'paged',
    skip: 0,
    take: 15,
  });

  const getTableData = (data: LeaderboardUser[] | LeaderboardPagedResults | undefined): LeaderboardUser[] => {
    if (Array.isArray(data)) {
      return data;
    }
    return data?.values || [];
  };

  const tableData = getTableData(leaderboardData);

  const onScopeFilterChange = (index: number) => {
    console.log(`Selected scope: ${scopeFilterLabels[index]?.value}`);
    setSelectedScopeFilter(scopeFilterLabels[index]?.value);
  };

  const onDateFilterChange = (index: number) => {
    console.log(`Selected date: ${dateFilterLabels[index]?.value}`);
  };

  return (
    <Card title={<h1>Leaderboard : {params.tenantId}</h1>}>
      <FiltersBar>
        <LabelledToggle
          labels={scopeFilterLabels}
          onSelect={(selectedIndex: number) => onScopeFilterChange(selectedIndex)}
        />
        <LabelledToggle
          labels={dateFilterLabels}
          onSelect={(selectedIndex: number) => onDateFilterChange(selectedIndex)}
        />
      </FiltersBar>

      {leaderboardData && (
        <Table data={tableData.length ? tableData : placeholderData} columns={columns} roundedRows hideSeparator />
      )}
    </Card>
  );
};

const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
