import type { FC } from 'react';
import { IconContainer, TrainingSecondaryButton, Title, TrainingCard } from '../TrainingCard';
import Play from '../../../../assets/Play.svg';
import { Coin } from '../../../Props';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';

type VoluntaryTrainingCardProps = {
  title: string;
  points?: number;
  onStart?: () => void;
};

export const VoluntaryTrainingCard: FC<VoluntaryTrainingCardProps> = ({
  title,
  points,
  onStart,
}: VoluntaryTrainingCardProps) => {
  return (
    <TrainingCard>
      <IconContainer>
        <Play className="rtl-flip" />
      </IconContainer>
      <Title>{title}</Title>
      {points && <Coin value={points} />}
      <TrainingSecondaryButton onClick={onStart}>
        <NamedContentEntryText container="span" refKey="yourTraining" subKey="startNow" />
      </TrainingSecondaryButton>
    </TrainingCard>
  );
};
