import { type ContentEntryRef } from '../types/ContentEntryRef';
import { pipeInto } from 'ts-functional-pipe';
import { _first, distinctBy, filter, flatMap, groupBy, leftOuterJoin, map, toArray } from 'ts-iterable-functions';
import type { FetchContentEntriesFunc } from '../types/FetchContentEntriesFunc';
import type { ContentEntryData, TextBlockData } from '../types/ContentEntryResponse';
import { memoizeUnaryLastValue } from '../../../functional/memoizeUnaryLastValue';
import { type ContentEntryResponsesOfContentEntryRefs } from '../types/ContentEntryResponsesOfContentEntryRefs';

const dedupeAndFetchContentEntriesImpl =
  (fetchContentEntries: FetchContentEntriesFunc) =>
  async <T extends (ContentEntryRef<ContentEntryData> | undefined)[]>(
    refs: readonly [...T],
  ): Promise<ContentEntryResponsesOfContentEntryRefs<T>> => {
    const results = await Promise.all(
      pipeInto(
        refs,
        distinctBy((r) => (r ? getContentEntryRefJoinKey(r) : 'missing')),
        groupBy((r) => (r ? `${r.moduleId}||${r.languageCode}` : undefined)),
        map(async (g) => {
          const firstInGroup = _first(g, (v) => v != null); //guaranteed to exist and guaranteed to have moduleId matching all others in group
          if (!firstInGroup) {
            return { moduleId: undefined, entries: [] };
          }
          const moduleId = firstInGroup.moduleId;
          const toFetch = pipeInto(
            g,
            filter((v) => v != null),
          );
          const entries = await fetchContentEntries([...toFetch] as readonly ContentEntryRef<TextBlockData>[]);
          return { moduleId, entries };
        }),
      ),
    );

    const flatResults = pipeInto(
      results,
      flatMap((r) =>
        r.entries.map((e) => ({
          ref: { contentId: e.contentId, moduleId: r.moduleId, languageCode: e.languageCode },
          entry: e,
        })),
      ),
      toArray(),
    );

    const returnValue = pipeInto(
      refs,
      leftOuterJoin(
        flatResults,
        (r) => (r ? getContentEntryRefJoinKey(r) : 'missing'),
        (fr) =>
          fr && fr.ref.moduleId ? getContentEntryRefJoinKey({ ...fr.ref, moduleId: fr.ref.moduleId }) : 'missing',
        (ref, fr) => ({ ref, entry: fr?.entry }),
      ),
      toArray(),
    );
    if (returnValue.length !== refs.length) {
      throw new Error('unexpected length mismatch');
    }

    return returnValue as ContentEntryResponsesOfContentEntryRefs<T>;
  };

export const dedupeAndFetchContentEntries = memoizeUnaryLastValue(dedupeAndFetchContentEntriesImpl);

function getContentEntryRefJoinKey(
  ref: Pick<ContentEntryRef<ContentEntryData>, 'contentId' | 'moduleId' | 'languageCode'>,
) {
  return `${ref.contentId}|${ref.moduleId}|${ref.languageCode}`;
}
