import { useEffect, useRef, useState } from 'react';

export function useTransitionVisibility<T extends HTMLElement>(visible: boolean) {
  const ref = useRef<T>(null);
  const [isMounted, setIsMounted] = useState(visible);
  useEffect(() => {
    if (visible) {
      setIsMounted(true);
      return undefined;
    }
    const menu = ref.current;
    if (!menu) {
      setIsMounted(false);
      return undefined;
    }
    const handler = () => {
      setIsMounted(false);
    };
    menu.addEventListener('transitionend', handler);
    return () => {
      menu.removeEventListener('transitionend', handler);
    };
  }, [visible]);
  return { ref, isMounted } as const;
}
