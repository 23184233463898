import { useEffect, useState } from 'react';
import { useStableFunction } from './useStableFunction';

/**
 * Custom hook that provides a countdown timer.
 *
 * @param countdownMS - The initial countdown time in milliseconds.
 * @param onComplete - Optional callback function to be called when the countdown reaches zero.
 * @returns The current countdown value in milliseconds.
 *
 * @example
 * const countdownValue = useCountdown(5000, () => {
 *   console.log('Countdown complete!');
 * });
 */
export const useCountdown = (countdownMS: number, onComplete?: () => void) => {
  const stableOnComplete = useStableFunction(onComplete);
  const [countdownValue, setCountdownValue] = useState<number>(countdownMS);
  useEffect(() => {
    if (countdownMS < 0) {
      return undefined;
    }
    let running = true;
    const startTimeMs = performance.now();
    const f = () => {
      const currentTimeMs = performance.now() - startTimeMs;
      const remainingCountdownMS = countdownMS - currentTimeMs;
      setCountdownValue(Math.ceil(Math.max(0, remainingCountdownMS) / 1000) * 1000);
      if (running) {
        if (remainingCountdownMS > 0) {
          requestAnimationFrame(f);
        } else {
          stableOnComplete();
          running = false;
        }
      }
    };
    f();
    return () => {
      running = false;
    };
  }, [countdownMS, stableOnComplete]);
  return countdownMS < 0 ? -1 : countdownValue;
};
