import ky, { type Options, HTTPError } from 'ky';
import { type StateCreator } from 'zustand';

// amount of time before OTP session expiry to refresh the OTP
const EXPIRY_MARGIN = 60000;

export type AuthStatus = 'loggedIn' | 'loggingIn' | 'loggedOut' | 'loggingOut';

export type LoginSettings = {
  portalEnabled: boolean;
  signInGuidance: boolean;
  signInGuidanceText: string | null | undefined;
  authenticationParameters: 'OTP';
  backgroundUrl: string | null | undefined;
  logoUrl: string | null | undefined;
};

type OTPRequestResponse = {
  expiry: string;
  requestId: string;
};

export type OTPRequestInfo = OTPRequestResponse & {
  receivedAt: string;
  email: string;
};

type TradeOTPResponse = {
  sessionId: string;
  userId: string;
  tenantCode: string;
  createdAt: string;
  expiresAt: string;
};

type AuthStoreState = {
  isFetching: boolean;
  tenantId?: string;
  error: string | null;
} & (
  | {
      authenticationState: 'loggedOut';
    }
  | {
      loginSettings: LoginSettings & { authenticationParameters: 'OTP' };
      authenticationState: 'loggedOut';
    }
  | {
      loginSettings: LoginSettings & { authenticationParameters: 'OTP' };
      authenticationState: 'otpRequested';
      otpRequestInfo: OTPRequestInfo;
    }
  | {
      loginSettings: LoginSettings & { authenticationParameters: 'OTP' };
      authenticationState: 'loggedIn';
      signInDetails: TradeOTPResponse;
    }
);
type AuthStoreActions = {
  init: (tenantId?: string) => void;
  requestOTP: (email: string) => Promise<AuthStoreActionStatus>;
  signInWithOTP: (otpCode: string) => Promise<AuthStoreActionStatus>;
  signOut: () => void;
  resetOtpRequestInfo: () => void;
};
type AuthStorePrivate = {
  timeouts: ReturnType<typeof setTimeout>[];
  refreshOTP: () => void;
  scheduleNextRefreshOTP: () => void;
  killScheduledRefresh: () => void;
};

type AuthStore = AuthStoreState & AuthStoreActions & AuthStorePrivate;

type AuthStoreActionStatus = {
  success: boolean;
  error?: string;
  code?: number;
};

const apiBaseURL = import.meta.env.REACT_APP_LP_API_BASE_URL as string | undefined;
if (apiBaseURL == null) {
  throw new Error('REACT_APP_LP_API_BASE_URL is not set');
}

export const auth: StateCreator<AuthStore> = (set, get) => {
  const store: AuthStore & AuthStorePrivate = {
    timeouts: [],
    isFetching: false,
    authenticationState: 'loggedOut',
    error: null,
    async init(tenantId) {
      const state = get();

      store.killScheduledRefresh();
      switch (state.authenticationState) {
        case 'loggedIn': {
          const timeToExpiry = new Date(state.signInDetails.expiresAt).getTime() - Date.now();
          // add a one second leeway because TTE is recalculated in the scheduleNextRefreshOTP function against the current time
          // which will have advanced a little after this function is called
          if (timeToExpiry > EXPIRY_MARGIN + 1000) {
            store.scheduleNextRefreshOTP();
            return;
          }
          console.log('OTP expired, unable to refresh. Logging out');
          set({
            otpRequestInfo: undefined,
            loginSettings: undefined,
            signInDetails: undefined,
          });
          set({ authenticationState: 'loggedOut' });
          break;
        }
        case 'otpRequested': {
          if (new Date(state.otpRequestInfo.expiry).getTime() > new Date().getTime()) {
            console.log('OTP not expired, state is good');
            return;
          }
          console.log('OTP expired, removing loginSettings and otpRequestInfo');
          set({
            otpRequestInfo: undefined,
            loginSettings: undefined,
          });
          break;
        }
        default:
          break;
      }

      console.log('Initializing');
      set({ tenantId, authenticationState: 'loggedOut', isFetching: true });
      try {
        const kyOpts: Options = {
          ...(tenantId ? { headers: { 'OT-Customer-Id': tenantId } } : {}),
        };
        const loginSettings = await ky.get(`${apiBaseURL}/login/settings`, kyOpts).json<LoginSettings>();
        set({ loginSettings });
      } finally {
        set({ isFetching: false });
      }
    },
    async requestOTP(email): Promise<AuthStoreActionStatus> {
      store.killScheduledRefresh();

      const state = get();

      if (!('loginSettings' in state)) {
        console.log('No login settings');
        return {
          success: false,
        };
      }
      const { tenantId } = state;
      set({ isFetching: true });
      try {
        const otpRequestResponse = await ky
          .post<OTPRequestResponse>(`${apiBaseURL}/otp/send`, {
            json: { email },
            ...(tenantId ? { headers: { 'OT-Customer-Id': tenantId } } : {}),
          })
          .json();

        const otpRequestInfo: OTPRequestInfo = { ...otpRequestResponse, receivedAt: new Date().toISOString(), email };
        set({ ...state, otpRequestInfo, authenticationState: 'otpRequested' }, true);

        return {
          success: true,
        };
      } catch (e) {
        if (e instanceof HTTPError) {
          if (e.response.status === 404) {
            return {
              success: false,
              error: "We couldn't find a user with that email address.",
            };
          }

          if (e.response.status === 400) {
            const errorMessage = await e.response.json();
            if (errorMessage?.reason) {
              return {
                success: false,
                error: "We couldn't find a user with that email address.",
              };
            }
          }
        }

        return {
          success: false,
          error: 'An unexpected error occurred. Please try again.',
        };
      } finally {
        set({ isFetching: false });
      }
    },
    async signInWithOTP(code): Promise<AuthStoreActionStatus> {
      const state = get();
      if (state.authenticationState !== 'otpRequested') {
        console.log('Not initialized or not in otpRequested state');
        return {
          success: false,
        };
      }
      const { otpRequestInfo, tenantId } = state;
      if (!otpRequestInfo) {
        console.log('No otpRequestInfo');
        return {
          success: false,
        };
      }
      if (new Date(otpRequestInfo.expiry) < new Date()) {
        console.log('OTP expired');
        return {
          success: false,
          error: 'Code expired',
        };
      }
      console.log('Signing in with OTP:', code);
      set({ isFetching: true });
      try {
        const signInDetails = await ky
          .post<TradeOTPResponse>(`${apiBaseURL}/otp/trade`, {
            json: { code, requestId: otpRequestInfo.requestId },
            ...(tenantId ? { headers: { 'OT-Customer-Id': tenantId } } : {}),
          })
          .json();

        set((s) => {
          if (!('loginSettings' in s)) {
            console.log('Not initialized or no login settings');
            return s;
          }
          return {
            ...s,
            initialized: true,
            signInDetails,
            loginSettings: s.loginSettings,
            authenticationState: 'loggedIn',
            isFetching: false,
            otpRequestInfo: undefined,
          };
        }, true);
        store.scheduleNextRefreshOTP();

        return {
          success: true,
        };
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 400) {
          return {
            success: false,
            error: 'Invalid Code Entered.',
          };
        }

        if (e instanceof HTTPError && e.response.status === 429) {
          return {
            success: false,
            error: 'Too many requests. Please wait 10 seconds and then try again.',
            code: e.response.status,
          };
        }

        return {
          success: false,
          error: 'An unexpected error occurred. Please try again.',
        };
      } finally {
        set({ isFetching: false });
      }
    },
    async refreshOTP() {
      const state = get();
      if (state.authenticationState !== 'loggedIn') {
        console.log('Not initialized or not logged in');
        return;
      }
      const { tenantId, signInDetails } = state;
      let signInDetailsNew: TradeOTPResponse;
      set({ isFetching: true });
      try {
        signInDetailsNew = await ky
          .post<TradeOTPResponse>(`${apiBaseURL}/otp/refresh`, {
            headers: {
              ...(tenantId ? { 'OT-Customer-Id': tenantId } : {}),
              Authorization: `OTP ${signInDetails.sessionId}`,
            },
          })
          .json();
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 401) {
          store.signOut();
          return;
        }
        throw e;
      } finally {
        set({ isFetching: false });
      }
      set({ signInDetails: signInDetailsNew });
      store.scheduleNextRefreshOTP();
    },
    signOut() {
      store.killScheduledRefresh();
      set((state) => {
        if (state.authenticationState !== 'loggedIn') {
          console.log('Not logged in');
          return state;
        }
        const { loginSettings, signInDetails: _, ...s } = state;
        return { ...s, authenticationState: 'loggedOut', loginSettings };
      }, true);
    },
    resetOtpRequestInfo() {
      set((state) => {
        if (state.authenticationState !== 'otpRequested') {
          console.log('Not waiting for OTP');
          return state;
        }
        const { loginSettings, otpRequestInfo: _, ...s } = state;
        return { ...s, authenticationState: 'loggedOut', loginSettings };
      }, true);
    },
    scheduleNextRefreshOTP() {
      store.killScheduledRefresh();
      const state = get();

      if (state.authenticationState !== 'loggedIn') {
        console.log("Not logged in. Won't schedule next refresh");
        return;
      }
      const { signInDetails } = state;
      const timeToExpiry = new Date(signInDetails.expiresAt).getTime() - Date.now();
      console.log('Time to expiry:', timeToExpiry);

      if (timeToExpiry < EXPIRY_MARGIN) {
        console.log("Expiry is in < 20s. Won't schedule next refresh");
        return;
      }

      const timeToNextRefresh = timeToExpiry - EXPIRY_MARGIN;
      console.log(`Time to expiry:${timeToExpiry}ms. Scheduling next refresh in ${timeToNextRefresh}ms`);

      const t = setTimeout(() => {
        store.refreshOTP();
      }, timeToNextRefresh);
      set((s) => ({ timeouts: [...s.timeouts, t] }));
    },
    killScheduledRefresh() {
      const state = get();
      const { timeouts } = state;
      console.log('killing ' + timeouts.length + ' scheduled refreshes');
      timeouts.forEach((t) => clearTimeout(t));
      set({ timeouts: [] });
    },
  };
  return store;
};
