import { useCallback, useState } from 'react';

export function useElemRef<E extends Element>(): readonly [E | undefined, (element: E) => void] {
  const [elem, setElem] = useState<E>();
  const ref = useCallback((element: E) => {
    if (element !== null) {
      setElem(element);
    }
  }, []);
  return [elem, ref] as const;
}
