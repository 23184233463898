export type LoginThemeConfig = {
  type: LoginThemes;
  defaultSplitScreenURL: string;
  defaultFullBackgroundURL: string;
};

export enum LoginThemes {
  'splitScreen',
  'fullBackground',
}

export function useLoginTheme(): LoginThemeConfig {
  const loginTheme: LoginThemeConfig = {
    type: LoginThemes.splitScreen,
    defaultSplitScreenURL: 'https://client-resources.outthink.io/auth/default-ciq-split.png',
    defaultFullBackgroundURL: 'https://client-resources.outthink.io/auth/default-ciq-full.png ',
  }; // TODO: Refactor after data fetching is implemented

  return loginTheme;
}
