import { type ContentEntryRef } from '../types/ContentEntryRef';
import { getContentEntryQueryKey } from './getContentEntryQueryKey';
import type { ContentEntryData, ContentEntryResponse } from '../types/ContentEntryResponse';
import type { Batcher } from '@yornaath/batshit';
import { queryOptions } from '@tanstack/react-query';
import type { Optional } from '../../../types/Optional';

export function createContentEntryQueryOptions<T extends ContentEntryData>(
  ref: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'> | undefined,
  batcher: Batcher<ContentEntryResponse<ContentEntryData>[], ContentEntryRef<T>, ContentEntryResponse<T> | undefined>,
) {
  return queryOptions({
    queryKey: ref ? getContentEntryQueryKey(ref) : ['contentEntry', 'missing'],
    queryFn: () =>
      ref && isContentEntryRef(ref) ? batcher.fetch(ref) : Promise.resolve({ ref, entry: undefined, missing: true }),
    staleTime: 3600000,
    gcTime: Infinity,
  });
}

function isContentEntryRef<T extends ContentEntryData>(
  ref: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'>,
): ref is ContentEntryRef<T> {
  return !!ref.contentId && !!ref.moduleId;
}
