import { Outlet, createBrowserRouter, json, redirect, useParams, type RouteObject } from 'react-router-dom';
import { PreserveSearchParams } from './routes/PreserveSearchParams';
import { ErrorRoute } from './routes/Error';
import { MainLayout } from './routes/MainLayout';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { Login } from './routes/Login';
import { topbarMenuPages } from './menuPages';
import { isUUID } from './lib/uuid/isUUID';
import { useEffect, useMemo } from 'react';
import { useZustandStore } from './appState/StoresProvider';
import { OneTimePassword } from './routes/OneTimePassword';
import { Spinner } from './components/Spinner/Spinner';
import styled, { useTheme } from 'styled-components';

const searchParamsToPreserve: string[] = ['contentEntryFallbacks'];

function getAppRoutes(isOutthinkOrigin: boolean, otpRequested: boolean) {
  const basicAppRoutes: RouteObject[] = [
    {
      index: true,
      loader({ params: { tenantId } }) {
        const loaderResponse = isOutthinkOrigin
          ? (testTenantId(tenantId) ?? redirect('overview'))
          : redirect('overview');
        return loaderResponse;
      },
    },
    {
      path: '',
      element: (
        <ProtectedRoute
          storePreviousLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus === 'loggedIn'}
          redirectTo="login"
        />
      ),
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: topbarMenuPages,
        },
      ],
    },
    {
      path: 'login',
      element: (
        <ProtectedRoute
          restoreStoredLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus !== 'loggedIn'}
          fallbackRedirectTo="../overview"
        />
      ),
      children: [
        {
          path: '',
          element: <LoginRoute />,
          children: [
            {
              path: '',
              element: otpRequested ? <OneTimePassword /> : <Login />,
            },
          ],
        },
      ],
    },
  ];
  return isOutthinkOrigin ? withTenantId(basicAppRoutes) : basicAppRoutes;
}

export function getRouter(origin: string, otpRequested: boolean) {
  const isOutthinkOrigin = origin === import.meta.env.REACT_APP_OUTTHINK_ORIGIN;
  const appRoutes = getAppRoutes(isOutthinkOrigin, otpRequested);
  return createBrowserRouter([
    {
      path: '',
      element: <Initialization />,
      errorElement: <ErrorRoute />,

      children: [
        {
          path: '',
          element: <PreserveSearchParams paramKeys={searchParamsToPreserve} />,

          children: [
            ...appRoutes,
            {
              path: '',
              loader({ params: { tenantId } }) {
                if (isOutthinkOrigin) {
                  try {
                    testTenantId(tenantId);
                  } catch {
                    return redirect('/DEMO');
                  }
                }
                throw json(null, { status: 404, statusText: 'Not found' });
              },
            },
          ],
        },
      ],
    },
  ]);
}
function withTenantId(appRoutes: RouteObject[]): RouteObject[] {
  return [
    {
      path: ':tenantId',
      loader({ params }) {
        return testTenantId(params.tenantId);
      },
      children: appRoutes,
    },
  ];
}
function testTenantId(tenantId: string | undefined) {
  if (!tenantId) {
    throw json(null, { status: 404, statusText: 'Not found. Invalid tenant code.' });
  }
  const isSegmentTenantCode = /^[A-Z]{4}$/.exec(tenantId);
  const isSegmentTrainingDeliveryPath = /^[a-z0-9]{4,25}$/.exec(tenantId);
  const isSegmentUUID = isUUID(tenantId);

  if (!isSegmentTenantCode && !isSegmentTrainingDeliveryPath && !isSegmentUUID) {
    throw json(null, { status: 404, statusText: 'Not found. Invalid tenant code.' });
  }
  return null;
}

const Initialization: React.FC = () => {
  const { tenantId } = useParams();
  const location = window.location;
  const isOutthinkOrigin = useMemo(
    () => location.origin === import.meta.env.REACT_APP_OUTTHINK_ORIGIN,
    [location.origin],
  );
  const init = useZustandStore('auth', (v) => v.init);
  const loginSettings = useZustandStore('auth', (v) => ('loginSettings' in v ? v.loginSettings : undefined));
  useEffect(() => {
    init(tenantId);
  }, [init, isOutthinkOrigin, tenantId]);
  const theme = useTheme();
  return loginSettings ? (
    <Outlet />
  ) : (
    <SpinnerContainer>
      <Spinner size={'64px'} color={theme.colors.primary} />
    </SpinnerContainer>
  );
};

const LoginRoute: React.FC = () => {
  // why? because we need to clear the language when we go to the login page
  // to prevent RTL mode from being applied to the login page
  // (login pages are always english for now)
  const clearLanguage = useZustandStore('language', (v) => v.clearLanguage);
  useEffect(() => {
    clearLanguage();
  }, [clearLanguage]);
  return <Outlet />;
};

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
