import { useElemRef } from './useElemRef';
import { type DOMRectReadOnly, useResizeObserver } from './useResizeObserver';

export type RRef<T> = {
  (element: T): void;
  getCurrent: () => T | undefined;
};

export function useResizeObserverWithRef<T extends Element>(): readonly [DOMRectReadOnly | undefined, RRef<T>] {
  const [elem, ref] = useElemRef<T>();
  const domRect = useResizeObserver(elem);
  const rref = ref as RRef<T>;
  rref.getCurrent = () => elem;
  return [domRect, rref] as const;
}
