import { useEffect, useMemo, useState } from 'react';
import { getRouter } from '../../getRouter';
import { useZustandStore } from '../../appState/StoresProvider';

export function useRouter() {
  const location = window.location;
  const otpRequestInfo = useZustandStore('auth', (v) =>
    v.authenticationState === 'otpRequested' ? v.otpRequestInfo : undefined,
  );
  const [origin, setOrigin] = useState(location.origin);
  useEffect(() => {
    setOrigin(location.origin);
  }, [location.origin]);
  const router = useMemo(() => {
    return getRouter(origin, !!otpRequestInfo); //, requestHeaders);
  }, [origin, otpRequestInfo]); //, requestHeaders]);
  return router;
}
