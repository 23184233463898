import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useSearchParam(searchParamKey: string) {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const contentEntryFallbacksOverride = searchParams.get(searchParamKey);
    return contentEntryFallbacksOverride;
  }, [location.search, searchParamKey]);
}
