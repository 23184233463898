import { type LegacyRef, type MutableRefObject } from 'react';

export function mergeRefs<T = unknown>(refs: (MutableRefObject<T> | LegacyRef<T>)[]): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
