import { type FC, useMemo } from 'react';
import { Card } from '../Card';
import { ExpandableSection } from './components/ExpandableSection';
import { CompletedTrainingCard } from './components/CompletedTrainingCard';
import styled from 'styled-components';
import { VoluntaryTrainingCard } from './components/VoluntaryTrainingCard';
import { MandatoryTrainingCard } from './components/MandatoryTrainingCard';
import AlertIcon from '../../assets/AlertIcon.svg';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { type EnrolledCampaignListResponse, useCampaignsQuery } from '../../apiHooks/useCampaignsQuery';

export const TrainingWidget: FC = () => {
  const { data: campaigns } = useCampaignsQuery(true);

  const completedCampaigns = useMemo(() => {
    if (!campaigns) {
      return [];
    }

    return campaigns.filter((campaign: EnrolledCampaignListResponse) => campaign.learnerStatus === 'completed');
  }, [campaigns]);

  const voluntaryCampaigns = useMemo(() => {
    if (!campaigns) {
      return [];
    }

    return campaigns.filter((campaign: EnrolledCampaignListResponse) => campaign.voluntary);
  }, [campaigns]);

  const mandatoryCampaigns = useMemo(() => {
    if (!campaigns) {
      return [];
    }

    return campaigns.filter((campaign: EnrolledCampaignListResponse) => !campaign.voluntary);
  }, [campaigns]);

  const urgentCampaigns = useMemo(() => {
    if (!mandatoryCampaigns.length) {
      return [];
    }

    return mandatoryCampaigns.filter((campaign: EnrolledCampaignListResponse) => {
      const now: Date = new Date();
      const dueDate: Date = new Date(campaign.dueDate);
      const timeDifference = dueDate.getTime() - now.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      return daysDifference < 2;
    });
  }, [mandatoryCampaigns]);

  const navigateToCampaignPage = (url: string | undefined) => {
    if (!url) {
      return null;
    }

    window.open(url, '_blank');
  };

  return (
    <Card
      title={<NamedContentEntryText container={'h2'} refKey="sectionTitles" subKey="yourTraining" />}
      rightElem={
        !!urgentCampaigns.length && (
          <HeaderRightElement>
            <AlertIcon />
            <label>
              <NamedContentEntryText container={'span'} refKey="yourTraining" subKey="urgent" />{' '}
              {urgentCampaigns.length}
            </label>
          </HeaderRightElement>
        )
      }
    >
      <ExpandableSection
        title={
          <label>
            <NamedContentEntryText container="span" refKey="yourTraining" subKey="mandatory" /> (
            {mandatoryCampaigns.length})
          </label>
        }
        defaultOpen
        disabled
      >
        <ExpandableSectionContent>
          {mandatoryCampaigns.map((campaign: EnrolledCampaignListResponse) => (
            <MandatoryTrainingCard
              key={campaign.id}
              title={campaign.name}
              completedModules={campaign.moduleProgress}
              totalModules={campaign.moduleTotal}
              dueDate={campaign.dueDate}
              onStart={() => navigateToCampaignPage(campaign.campaignUrl)}
            />
          ))}
        </ExpandableSectionContent>
      </ExpandableSection>
      <ExpandableSection
        title={
          <label>
            <NamedContentEntryText container="span" refKey="yourTraining" subKey="voluntary" /> (
            {voluntaryCampaigns.length})
          </label>
        }
        defaultOpen={!!voluntaryCampaigns.length}
      >
        <ExpandableSectionContent>
          {voluntaryCampaigns.map((campaign: EnrolledCampaignListResponse) => (
            <VoluntaryTrainingCard
              key={campaign.id}
              title={campaign.name}
              points={campaign.pointsAward}
              onStart={() => navigateToCampaignPage(campaign.campaignUrl)}
            />
          ))}
        </ExpandableSectionContent>
      </ExpandableSection>
      <ExpandableSection
        title={
          <label>
            <NamedContentEntryText container="span" refKey="yourTraining" subKey="completed" /> (
            {completedCampaigns.length})
          </label>
        }
        defaultOpen={!!completedCampaigns.length}
      >
        <ExpandableSectionContent>
          {completedCampaigns.map((campaign: EnrolledCampaignListResponse) => (
            <CompletedTrainingCard
              key={campaign.id}
              title={campaign.name}
              onRevisit={() => navigateToCampaignPage(campaign.campaignUrl)}
            />
          ))}
        </ExpandableSectionContent>
      </ExpandableSection>
    </Card>
  );
};

const ExpandableSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 10px;
`;

const HeaderRightElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.urgent};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
`;
