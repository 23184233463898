import { useLPQuery } from '../lib/hooks/useLPQuery';

export function useMeQuery() {
  return useLPQuery<Me>(['me'], '/me');
}
type Me = {
  learnerId: string;
  name?: string | null;
  portalRole: string;
  authOption?: string | null;
  languageCode?: string | null;
  country?: string | null;
};
