import DOMPurify from 'dompurify';

export function toSanitizedDOM(html: string): HTMLElement {
  const node = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['a', 'strong', 'em', 'span', 'mark', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    ALLOW_DATA_ATTR: false,
    ALLOWED_ATTR: ['href', 'dir'],
    RETURN_DOM: true,
  });
  if (!(node instanceof HTMLElement)) {
    throw new Error('Expected a HTMLElement');
  }
  return node;
}
