import { type Optional } from '../../../types/Optional';
import type { ContentEntryRef } from './ContentEntryRef';
import type { ContentEntryData } from './ContentEntryResponse';

export type ContentEntryRefWithFallback<T extends ContentEntryData> = Optional<
  ContentEntryRef<T>,
  'contentId' | 'moduleId'
> & {
  fallbackData: T & { type: T['type'] };
};

export function isContentEntryRefWithFallback<T extends ContentEntryData>(
  ref: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'>,
): ref is ContentEntryRefWithFallback<T> {
  return 'fallbackData' in ref;
}
