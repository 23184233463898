import { type FC, type PropsWithChildren, type ComponentProps } from 'react';
import styled from 'styled-components';
import { Button } from '.';
import { Spinner } from '../Spinner/Spinner';

export const ButtonWithSpinner: FC<
  PropsWithChildren<{ spinnerSize: string; spinnerColor: string; showSpinner: boolean } & ComponentProps<typeof Button>>
> = ({ spinnerSize, spinnerColor, showSpinner, children, ...props }) => {
  return (
    <CustButton $spinnerSize={spinnerSize} {...props}>
      <span>{children}</span>
      <Spinner color={spinnerColor} size={spinnerSize} style={{ opacity: showSpinner ? 1 : 0 }} />
    </CustButton>
  );
};

const CustButton = styled(Button)<{ $spinnerSize: string }>`
  ::before {
    content: ' ';
    display: inline-block;
    width: ${(props) => props.$spinnerSize};
    height: ${(props) => props.$spinnerSize};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
