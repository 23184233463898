import { useMemo } from 'react';
import { useLanguageContext } from '../lib/hooks/useLanguageContext';
import { toContentEntryRefs } from './util/toContentEntryRefs';

export type TextBlockRefLight =
  | {
      nested: false;
      contentId?: string;
      fallback: string[];
    }
  | {
      nested: true;
      contentId?: string;
      fallback: string[];
      propSubKeyIndexes: Record<string, number>;
    };

export type SnapshotLevel = 'Vulnerable' | 'Determined' | 'Solid' | 'Armored' | 'Unbreakable';
export type SecuritySnapshotLevelKey = `securitySnapshotLevels${SnapshotLevel}`;

const levelSubKeyIndexes = {
  levelN: 0,
  levelName: 1,
  bodyHeading: 2,
  bodyMain: 3,
} as const;

const securitySnapshotLevelRefs = {
  securitySnapshotLevelsVulnerable: {
    nested: true,
    contentId: '380cfd40-dd96-4ee3-b0b0-0584ca7db30f',
    fallback: [
      'Level 1',
      'Vulnerable',
      'Welcome to the start of your cybersecurity journey!',
      "As a VULNERABLE user, it's important you start behaving more securely.  Check out the breakdown below to see how you can improve, earn CyberIQ points and climb the ranks!",
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsDetermined: {
    nested: true,
    contentId: 'a57e7591-e65a-4a40-8beb-3a48ea3ba076',
    fallback: [
      'Level 2',
      'Determined',
      "You're making good progress!",
      'You are DETERMINED and your efforts are starting to pay off. Keep pushing forward and you’ll see even more improvement! Every action you take helps protect our company.',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsSolid: {
    nested: true,
    contentId: '24191911-5955-42c9-898a-af3d3a6bdd80',
    fallback: [
      'Level 3',
      'Solid',
      "You're doing well!",
      "You are SOLID and your consistent efforts are strengthening our company's security. Keep up the good work and continue to build on your progress!",
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsArmored: {
    nested: true,
    contentId: 'cfba9c39-fee3-42a6-9403-e29bd4949b0d',
    fallback: [
      'Level 4',
      'Armored',
      "You're doing fantastic!",
      'You are ARMORED and your dedication to cybersecurity is making a significant impact. Thank you for helping to keep our company safe. Keep it up!',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsUnbreakable: {
    nested: true,
    contentId: 'a49438ec-6e85-4e85-8a16-ee04be7f2efb',
    fallback: [
      'Level 5',
      'Unbreakable',
      'Congratulations!',
      'You are UNBREAKABLE! Your commitment to cybersecurity is exemplary. Thank you for being a role model and keeping our company secure!',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
} as const satisfies Record<SecuritySnapshotLevelKey, TextBlockRefLight>;

type ScoreBreakdownItem =
  | 'CompleteAllTraining'
  | 'MaintainHighEngagement'
  | 'BuildYourKnowledge'
  | 'ReportAttacks'
  | 'AvoidClicking';

type ScoreBreakdownKey = `scoreBreakdown${ScoreBreakdownItem}`;

const scoreBreakdownRefs = {
  scoreBreakdownCompleteAllTraining: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Complete all training',
      'You have one overdue mandatory cybersecurity training. Completing this training on time is crucial to keeping both you and the company safe and compliant.',
    ],
    contentId: '92f9eae5-7c01-4173-8f24-81bf1409ef01',
  },
  scoreBreakdownMaintainHighEngagement: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Maintain high engagement',
      'Your engagement is up last 30 days with 2%. Great! Keep asking questions, completing modules and sharing tips with your colleagues This helps the company and you to stay safe.',
    ],
    contentId: '0b8f2655-cd98-4d4e-bf51-6565788dedea',
  },
  scoreBreakdownBuildYourKnowledge: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Build your knowledge',
      'Your training knowledge has declined over last 30 days and is lower compared to average.In future sessions make sure to revisit the training materials before you take the knowledge assessment questions.',
    ],
    contentId: '440b0266-8eca-4420-938b-fe880a4fdddc',
  },
  scoreBreakdownReportAttacks: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Report attacks',
      'In the past 30 days, you spotted and reported 3 out of 5 phishing simulations. Great job! Keep it up to earn more points—each identified phish earns you 250 CyberIQ points.',
    ],
    contentId: 'def3522e-52b9-4e2d-bdd1-3258fdbde28d',
  },
  scoreBreakdownAvoidClicking: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Avoid clicking',
      'There were no simulations issued in last 30 days. Stay vigilant and earn points by reporting them!',
    ],
    contentId: '56c3b9b6-b006-40b9-b199-9fbe8a25abb4',
  },
} as const satisfies Record<ScoreBreakdownKey, TextBlockRefLight>;

const simpleTextBlockRefs = {
  ...scoreBreakdownRefs,
  ...securitySnapshotLevelRefs,
  menuSystem: {
    nested: true,
    contentId: '79679d27-4477-4cda-ba86-f3506371531d',
    fallback: [
      'Overview',
      'Leaderboard',
      'Challenges & Activities',
      'User view',
      'Manager view',
      'User',
      'Manager',
      'Support',
      'Sign out',
    ],
    propSubKeyIndexes: {
      overview: 0,
      leaderboard: 1,
      challengesActivities: 2,
      userView: 3,
      managerView: 4,
      user: 5,
      manager: 6,
      support: 7,
      signOut: 8,
    },
  },
  sectionTitles: {
    nested: true,
    contentId: 'b583e226-b5d5-4ca8-9ecf-a4118334d504',
    fallback: [
      'SECURITY SNAPSHOT',
      'LEADERBOARD',
      'SCORE BREAKDOWN',
      'YOUR TRAINING',
      'ACTIVITY OVERVIEW',
      'ACTIVITIES',
    ],
    propSubKeyIndexes: {
      securitySnapshot: 0,
      leaderboard: 1,
      scoreBreakdown: 2,
      yourTraining: 3,
      activityOverview: 4,
      activities: 5,
    },
  },

  securitySnapshot: {
    nested: true,
    contentId: 'd0e1be8e-841b-4534-aab6-6cf40fa3eeae',
    fallback: ['CyberIQ Points'],
    propSubKeyIndexes: {
      cyberiqPoints: 0,
    },
  },
  leaderboard: {
    nested: true,
    contentId: '74f39d99-e463-4c39-af93-866b0725534f',
    fallback: [
      'My team',
      'My department',
      'My company',
      'All time',
      'This month',
      'View more...',
      'Rank',
      'Name',
      'Badges',
      'Level',
      'Points',
      'How to earn',
    ],
    propSubKeyIndexes: {
      myTeam: 0,
      myDepartment: 1,
      myCompany: 2,
      allTime: 3,
      thisMonth: 4,
      viewMore: 5,
      rank: 6,
      name: 7,
      badges: 8,
      level: 9,
      points: 10,
      howToEarn: 11,
    },
  },
  yourTraining: {
    nested: true,
    contentId: '62fceac2-287d-4869-8ada-8c3bc15693e8',
    fallback: ['Mandatory', 'Voluntary', 'Completed', 'Urgent:', 'Due date:', 'Start now', 'Revisit', 'Resume'],
    propSubKeyIndexes: {
      mandatory: 0,
      voluntary: 1,
      completed: 2,
      urgent: 3,
      dueDate: 4,
      startNow: 5,
      revisit: 6,
      resume: 7,
    },
  },
  activityOverview: {
    nested: true,
    contentId: 'c5e93e0d-e7a9-49d4-8725-e323b8511ca6',
    fallback: [
      'Your points',
      'Company average',
      'Recent activity',
      'Date',
      'Points',
      'Reported Phishing Simulation',
      'Completed Cyber Assessment',
      'Optional Phishing Game Completed',
      'Reported Suspected Phish',
      'Mandatory Training',
    ],
    propSubKeyIndexes: {
      yourPoints: 0,
      companyAverage: 1,
      recentActivity: 2,
      date: 3,
      points: 4,
      reportedPhishingSimulation: 5,
      completedCyberAssessment: 6,
      optionalPhishingGameCompleted: 7,
      reportedSuspectedPhish: 8,
      mandatoryTraining: 9,
    },
  },
  activities: {
    nested: true,
    contentId: 'a24c1a9e-28d0-46f9-a88e-f23b12b498e1',
    fallback: ['Start now', '{{completionMinutes}} mins'],
    propSubKeyIndexes: {
      startNow: 0,
      completionminutesMins: 1,
    },
  },
} as const satisfies Record<string, TextBlockRefLight>;

const contentEntryRefs = toContentEntryRefs(simpleTextBlockRefs);

export type ContentEntryRefs = typeof contentEntryRefs;
export type ContentEntryRefsKey = keyof ContentEntryRefs;

type ContentEntryRefsTuple<K extends (ContentEntryRefsKey | undefined)[]> = {
  [Idx in keyof K]: K[Idx] extends ContentEntryRefsKey
    ? (ContentEntryRefs[K[Idx]] & { languageCode: string }) | undefined
    : undefined;
} & {
  length: K['length'];
};

export function useContentEntryRefs<K extends (ContentEntryRefsKey | undefined)[]>(
  keys: readonly [...K] | readonly [],
): ContentEntryRefsTuple<K> {
  const [languageCode] = useLanguageContext();

  return useMemo(
    () =>
      languageCode ? getContentEntryRefs(keys, languageCode) : (keys.map(() => undefined) as ContentEntryRefsTuple<K>),
    [keys, languageCode],
  );
}
export function getContentEntryRefs<K extends (ContentEntryRefsKey | undefined)[]>(
  keys: readonly [...K] | readonly [],
  languageCode: string,
): ContentEntryRefsTuple<K> {
  return keys.map((key) => (key ? { ...contentEntryRefs[key], languageCode } : undefined)) as ContentEntryRefsTuple<K>;
}
