import styled, { keyframes } from 'styled-components';

const rotationKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.span<{ size: string; color: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid ${(props) => props.color};
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: ${rotationKeyframes} 1s linear infinite;
`;
