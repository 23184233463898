import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LeaderboardWidgetUI } from '../Leaderboard/LeaderboardWidgetUI';
import { SecuritySnapshotUI } from '../SecuritySnapshot/SecuritySnapshotUI';
import { TrainingWidget } from '../Activity/TrainingWidget';
import { ScoreBreakdown } from '../ScoreBreakdown/ScoreBreakdown';

export const Overview: React.FC = () => {
  const params = useParams();
  console.log(params);

  return (
    <Layout>
      <Column>
        <SecuritySnapshotUI />
        <ScoreBreakdown />
      </Column>
      <Column>
        <LeaderboardWidgetUI />
        <TrainingWidget />
      </Column>
    </Layout>
  );
};
const Layout = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
