import { type TopBarUIProps } from '../TopBarUI';
import { topbarMenuPages } from '../../../menuPages';
import { useMemo } from 'react';
import logo from '../assets/OTLogo.svg?url';
import { useZustandStore } from '../../../appState/StoresProvider';
import { useMeQuery } from '../../../apiHooks/useMeQuery';
import { useAvatarQuery } from '../../../apiHooks/useAvatarQuery';
import { useQueryClient } from '@tanstack/react-query';

export function useTopBarUIProps(): TopBarUIProps {
  const signOut = useZustandStore('auth', (v) => v.signOut);
  const { data: meData } = useMeQuery();
  const { data: avatarData } = useAvatarQuery();
  const queryClient = useQueryClient();
  return useMemo(
    () =>
      ({
        pages: topbarMenuPages,
        user: meData
          ? {
              name: meData.name || null,
              role: (meData.portalRole as 'learner' | 'admin') || null,
              avatarUrl: avatarData?.avatar || null,
            }
          : {
              name: null,
              role: null,
              avatarUrl: avatarData?.avatar || null,
            },
        logoUrl: logo,
        onSignOut: () => {
          queryClient.clear();
          signOut();
        },
      }) satisfies TopBarUIProps,
    [avatarData, meData, signOut, queryClient],
  );
}
