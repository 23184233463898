export const memoizeUnaryLastValue = <P, R>(
  fn: UnaryFunc<P, R>,
  equalityFn: (p1: P, p2: P) => boolean = eq,
): UnaryFunc<P, R> => {
  let lastCall: { param: P; returnValue: R };
  const fnWrapped = (p: P) => {
    if (lastCall && equalityFn(lastCall.param, p)) {
      return lastCall.returnValue;
    } else {
      const ret = fn(p);
      lastCall = { param: p, returnValue: ret };
      return ret;
    }
  };
  return fnWrapped;
};

type UnaryFunc<P, R> = (p: P) => R;

function eq<T>(a: T, b: T) {
  return a === b;
}
