import { type StateCreator } from 'zustand';

export const language: StateCreator<LanguageStore> = (set) => {
  return {
    languageCode: undefined,
    changeLanguage: (language) => {
      set({ languageCode: language });
    },
    clearLanguage: () => set({ languageCode: undefined }),
  };
};
type LanguageStore = {
  languageCode?: string;
  changeLanguage: (language: string) => void;
  clearLanguage: () => void;
};
