import { useCallback, useRef } from 'react';

/**
 * A custom hook that returns a stable version of the provided function.
 * The returned function will always have the same reference, but will call the latest version of the provided function.
 *
 * @template A - The argument types of the function.
 * @template R - The return type of the function.
 * @param func - The function to be stabilized.
 * @returns A stable version of the provided function.
 */

export function useStableFunction<A extends [...args: unknown[]], R>(
  func: ((...args: A) => R) | undefined,
): (...args: A) => R | undefined {
  const rr = useRef(func);
  rr.current = func;
  const f = useCallback((...args: A) => rr.current?.(...args), []);
  return f;
}
