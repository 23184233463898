import styled from 'styled-components';
import type { FC } from 'react';
import { Icon, IconType } from '../Icon/Icon';

type ProgressArrowProps = {
  value: number;
};

export const ProgressArrow: FC<ProgressArrowProps> = ({ value }: ProgressArrowProps) => {
  const isPositive: boolean = value >= 0;
  const formattedValue = isPositive ? `+${value}` : `${value}`;
  const iconType: IconType = isPositive ? IconType.PROGRESS_ARROW_UP : IconType.PROGRESS_ARROW_DOWN;
  return (
    <Container>
      <Value isPositive={isPositive}>{formattedValue}</Value>
      <Icon className="rtl-flip" icon={iconType} width={20} height={20} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Value = styled.span<{ isPositive: boolean }>`
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  color: ${({ isPositive }) => (isPositive ? '#118A5D' : '#C11414')};
`;
