import { useMemo, type FC } from 'react';
import { useTheme } from 'styled-components';

export const Pie: FC<{ value: number; size: number; strokeWidth: number }> = ({ value, size, strokeWidth }) => {
  const {
    colors: { primary },
  } = useTheme();
  const { x, y, d, v } = useMemo(() => {
    const v = 1 - Math.min(0.999999999, value);
    const d = size - strokeWidth;
    const x = strokeWidth / 2 + (d / 2 + (Math.sin(v * 2 * Math.PI) * d) / 2);
    const y = strokeWidth / 2 + (d / 2 + (-Math.cos(v * 2 * Math.PI) * d) / 2);
    return { x, y, s: size, sw: strokeWidth, d, v };
  }, [size, strokeWidth, value]);

  return (
    <svg width={size} height={size}>
      <path
        d={`M ${size / 2} ${strokeWidth / 2} A ${d / 2} ${d / 2} 0 ${v < 0.5 ? 1 : 0} 0 ${x} ${y}`} // 1 0 when < 0.5 else 0 0
        stroke={primary}
        strokeLinecap="butt"
        strokeWidth={strokeWidth}
        fill="none"
      />
    </svg>
  );
};
