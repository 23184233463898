import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useResizeObserverWithRef } from '../../../lib/hooks/useResizeObserverWithRef';
import type { DOMRectReadOnly } from '../../../lib/hooks/useResizeObserver';
import { useMergedRefs } from '../../../lib/hooks/useMergedRefs';
import { useStableFunction } from '../../../lib/hooks/useStableFunction';
import { useIsRTLLayout } from '../../../appState/hooks/useIsRTLLayout';

export type LabelledToggleOption = {
  label: ReactNode;
  selected?: boolean;
  value: string;
};

type LabelledToggleProps = {
  labels: LabelledToggleOption[];
  onSelect?: (selectedIndex: number) => void;
};

export const LabelledToggle: FC<LabelledToggleProps> = ({ labels, onSelect }: LabelledToggleProps) => {
  const [selectedIndex, setSelectedIndex] = useState(
    labels.findIndex((option: LabelledToggleOption) => option.selected) || 0,
  );
  const [highlightStyle, setHighlightStyle] = useState({ width: 0, left: 0 });

  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  // This is now called in response to
  // a) the component mounting
  // b) the selected index changing
  // c) the labels changing
  // d) the RTL layout changing
  // e) onResizeObserved events from the ToggleOption components
  const updateHighlight = useCallback(() => {
    const selectedOption = optionRefs.current[selectedIndex];
    if (selectedOption) {
      const { offsetWidth: width, offsetLeft: left } = selectedOption;
      setHighlightStyle({ width, left });
    }
  }, [selectedIndex]);

  const isRtl = useIsRTLLayout();

  useEffect(() => {
    // Apparently, sometimes we need to wait for the DOM
    // to update before we can measure the selected option
    const t = setTimeout(() => updateHighlight(), 0);
    return () => {
      clearTimeout(t);
    };
  }, [updateHighlight, isRtl, labels]);

  return (
    <StyledLabelledToggle>
      <ToggleContainer>
        {labels.map((option: LabelledToggleOption, index: number) => (
          <ToggleOption
            key={option.value}
            ref={(el) => (optionRefs.current[index] = el)}
            onClick={() => {
              setSelectedIndex(index);
              if (onSelect) {
                onSelect(index);
              }
            }}
            isSelected={index === selectedIndex}
            onResizeObserved={updateHighlight}
          >
            {option.label}
          </ToggleOption>
        ))}
        <Highlight style={highlightStyle} />
      </ToggleContainer>
    </StyledLabelledToggle>
  );
};

const StyledLabelledToggle = styled.div`
  display: inline-flex;
  padding: 4px;
  border: 1px solid #ddd;
  background-color: ${({ theme }) => theme.colors.pageBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`;

const ToggleOption = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof ToggleOptionComp> & { onResizeObserved?: (rect: DOMRectReadOnly | undefined) => void }
>(function ToggleOption({ children, onResizeObserved, ...props }, ref) {
  const masterRef = useWireUpResizeObservedCallback(onResizeObserved, ref);
  return (
    <ToggleOptionComp ref={masterRef} {...props}>
      {children}
    </ToggleOptionComp>
  );
});

function useWireUpResizeObservedCallback(
  onResizeObserved: ((rect: DOMRectReadOnly | undefined) => void) | undefined,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const [rect, rref] = useResizeObserverWithRef();
  const stableOnResizeObserved = useStableFunction(onResizeObserved);
  useEffect(() => {
    stableOnResizeObserved(rect);
  }, [stableOnResizeObserved, rect]);
  const refsToMerge = useMemo(() => [rref, ref], [rref, ref]);
  const masterRef = useMergedRefs(refsToMerge);
  return masterRef;
}

const ToggleOptionComp = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 6px 12px;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ isSelected }) => (isSelected ? '#5B59EF' : '#555')};
  z-index: 1;
  position: relative;
  user-select: none;
`;

const Highlight = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primaryContrast};
  transition:
    width 0.3s ease,
    left 0.3s ease,
    right 0.3s ease; /* In RTL mode, this is important */
  z-index: 0;
  box-shadow: ${({ theme }) => theme.shadow.light};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
