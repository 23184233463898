import { LabelledToggle } from '../Toggle';
import { Table } from '../Table';
import styled from 'styled-components';
import type { LeaderboardUser } from '../../lib/types/LeaderboardUser';
import { type LeaderboardPagedResults, useLeaderboardQuery } from '../../apiHooks/useLeaderboardQuery';
import { useLeaderboardWidgetData } from './hooks/useLeaderboardWidgetData';
import { useState } from 'react';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

export const LeaderboardWidgetUI = () => {
  const { placeholderData, columns, scopeFilterLabels, dateFilterLabels } = useLeaderboardWidgetData();
  const [selectedScopeFilter, setSelectedScopeFilter] = useState<string | undefined>(scopeFilterLabels[0]?.value);

  const { data: leaderboardWidgetData } = useLeaderboardQuery({
    type: selectedScopeFilter || 'company',
    mode: 'top',
    count: 5,
  });

  const getTableData = (data: LeaderboardUser[] | LeaderboardPagedResults | undefined): LeaderboardUser[] => {
    if (Array.isArray(data)) {
      return data;
    }
    return data?.values || [];
  };

  const tableData = getTableData(leaderboardWidgetData);

  const onScopeFilterChange = (index: number) => {
    console.log(`Selected scope: ${scopeFilterLabels[index]?.value}`);
    setSelectedScopeFilter(scopeFilterLabels[index]?.value);
  };

  const onDateFilterChange = (index: number) => {
    console.log(`Selected date: ${dateFilterLabels[index]?.value}`);
  };

  return (
    <Card title={<NamedContentEntryText container={'h2'} refKey="sectionTitles" subKey="leaderboard" />}>
      <FiltersBar>
        <LabelledToggle
          labels={scopeFilterLabels}
          onSelect={(selectedIndex: number) => onScopeFilterChange(selectedIndex)}
        />
        <LabelledToggle
          labels={dateFilterLabels}
          onSelect={(selectedIndex: number) => onDateFilterChange(selectedIndex)}
        />
      </FiltersBar>

      {leaderboardWidgetData && (
        <Table
          data={tableData.length ? tableData : placeholderData}
          columns={columns}
          hideHeaders
          roundedRows
          hideSeparator
        />
      )}
    </Card>
  );
};

const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
