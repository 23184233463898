import type { PropsWithChildren, ReactHTML } from 'react';
import React from 'react';
import { useContentEntryRefs } from '../../contentEntryRefs';
import type { ContentEntryRefs } from '../../contentEntryRefs/contentEntryRefs';
import { ContentEntryText } from './ContentEntryText';

export type NamedContentEntryTextProps<RefKey extends keyof ContentEntryRefs> = {
  refKey: RefKey;
  asHtml?: boolean;
  container: React.ComponentType<PropsWithChildren> | keyof ReactHTML;
} & (ContentEntryRefs[RefKey] extends { nested: true; propSubKeyIndexes: infer P } ? { subKey: keyof P } : object);

export const NamedContentEntryText = <RefKey extends keyof ContentEntryRefs>({
  refKey,
  ...rest
}: NamedContentEntryTextProps<RefKey>) => {
  const [ref] = useContentEntryRefs([refKey]);
  return ref && <ContentEntryText contentEntryRef={ref} {...rest} />;
};
