import type { FC } from 'react';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Table } from '../Table';
import { type LearnerActivity, useLatestActivitiesQuery } from '../../apiHooks/useLatestActivitiesQuery';
import type { Column } from '../Table/Table';
import { useLanguageContext } from '../../lib/hooks/useLanguageContext';
import { Coin } from '../Props';

export const ActivityOverviewWidget: FC = () => {
  const { data: latestActivitiesResponse } = useLatestActivitiesQuery();
  const latestActivities: LearnerActivity[] = latestActivitiesResponse?.learnerActivities || [];
  const [langCode] = useLanguageContext();

  const columns: Column<LearnerActivity>[] = [
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="recentActivity" />,
      valueSelector: (activity: LearnerActivity) => activity.actionScoreType,
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="date" />,
      valueSelector: (activity: LearnerActivity) => new Intl.DateTimeFormat(langCode).format(activity.createdTime),
      width: '100px',
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="points" />,
      valueSelector: (activity: LearnerActivity) => activity.points && <Coin value={activity.points} />,
      width: 'min-content',
      alignment: 'right',
    },
  ];

  return (
    <Card title={<NamedContentEntryText container={'h2'} refKey="sectionTitles" subKey="activityOverview" />}>
      <Table data={latestActivities} columns={columns} />
    </Card>
  );
};
