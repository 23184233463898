import React, { type FC, useCallback, useEffect, useRef, useState } from 'react';
// import styled from 'styled-components';

export const Flag: FC<React.PropsWithChildren<{ alpha2Code: string; className?: string }>> = ({
  alpha2Code,
  className,
}) => {
  const [error, setError] = useState(false);

  const [src, setSrc] = useState<{
    default: React.FunctionComponent<
      React.ComponentProps<'svg'> & { title?: string; titleId?: string; desc?: string; descId?: string }
    >;
  }>();
  const isMounted = useMountedState();

  useEffect(() => {
    const flagFileName = `${error ? 'unknown' : alpha2Code}`;
    import(`./assets/flags/${flagFileName}.svg`)
      .then((v) => isMounted() && setSrc(v))
      .catch(() => isMounted() && setError(true));
  }, [alpha2Code, error, isMounted]);

  const C = src?.default;
  return C && <C className={className} />;
};

function useMountedState(): () => boolean {
  const mountedRef = useRef<boolean>(false);
  const get = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);
  return get;
}
