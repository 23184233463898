import styled, { css } from 'styled-components';

export const ellipsisCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Ellipsis = styled.div`
  ${ellipsisCSS}
`;
