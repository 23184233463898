import React, { type FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useIsRTLLayout } from '../appState/hooks/useIsRTLLayout';
import { useLanguageContext } from '../lib/hooks/useLanguageContext';

const iconPath = `/favicon32.png`;

export const WrapHelmet: FC<React.PropsWithChildren> = ({ children }) => {
  const isRTLLayout = useIsRTLLayout();
  const [language] = useLanguageContext();
  const htmlAttrs = isRTLLayout ? { dir: 'rtl', lang: language } : { dir: 'ltr', lang: language };
  return (
    <HelmetProvider>
      <Helmet htmlAttributes={htmlAttrs}>
        <title>CyberIQ Portal</title>
        <link rel="icon" href={iconPath} />
        <link rel="apple-touch-icon" href={iconPath} />
        <meta name="version" content={import.meta.env.REACT_APP_BUILD_NUMBER ?? 'not available'} />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};
