import { Navigate, Outlet, useLocation, type NavigateProps } from 'react-router-dom';
import React from 'react';
import { useLoginStatus } from '../appState/hooks/auth/useLoginStatus';

type ProtectedRouteProps = { allowWhen: (loginStatus: ReturnType<typeof useLoginStatus>) => boolean } & (
  | {
      storePreviousLocationOnRedirect: true;
      redirectTo: string;
    }
  | { restoreStoredLocationOnRedirect: true; fallbackRedirectTo: string }
  | { redirectTo: string }
);

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const location = useLocation();
  const loginStatus = useLoginStatus();
  const allowed = props.allowWhen(loginStatus);
  if (allowed) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    const to =
      'restoreStoredLocationOnRedirect' in props
        ? props.restoreStoredLocationOnRedirect
          ? (location.state?.from ?? props.fallbackRedirectTo)
          : null
        : props.redirectTo;
    const navProps: NavigateProps = {
      to,
      replace: true,
      ...('storePreviousLocationOnRedirect' in props && props.storePreviousLocationOnRedirect
        ? { state: { from: location } }
        : {}),
    };
    return <Navigate {...navProps} />;
  }
};
