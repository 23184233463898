import { useMemo } from 'react';
import { pipeInto } from 'ts-functional-pipe';
import { count, flatMap, groupBy, indexed, map, toArray, unwrapIndexed } from 'ts-iterable-functions';
import { useLanguagesQuery } from '../../../apiHooks/useLanguagesQuery';

export function useLanguagesWithAlpha2Codes() {
  const languagesQueryResult = useLanguagesQuery();
  const languages = useMemo(() => languagesQueryResult.data?.languages ?? [], [languagesQueryResult.data?.languages]);

  const languagesWithAlpha2Codes = useMemo(
    () =>
      pipeInto(
        languages,
        map((lang) => ({
          ...lang,
          alpha2Code: toFlagName(lang.code),
        })),
        indexed(),
        groupBy(([v]) => v.name),
        flatMap((g) =>
          pipeInto(g, count()) <= 1
            ? g
            : pipeInto(
                g,
                map(([v, i]) => [{ ...v, name: `${v.name} (${v.alpha2Code})` }, i] as const),
              ),
        ),
        unwrapIndexed(),
        toArray(),
      ),
    [languages],
  );
  return languagesWithAlpha2Codes;
}
function toFlagName(langCode: string) {
  const cc = langCode.split('-')[1];
  const returnVal = cc === 'Hans' ? 'CN' : cc;
  if (!returnVal) {
    throw new Error(`No flagname generated for language code ${langCode}`);
  }
  return returnVal;
}
