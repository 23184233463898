import { useMemo, type FC } from 'react';
import { IconContainer, Title, TrainingCard, TrainingPrimaryButton } from '../TrainingCard';
import GoalFlag from '../../../../assets/GoalFlag.svg';
import Calendar from '../../../../assets/Calendar.svg';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useLanguageContext } from '../../../../lib/hooks/useLanguageContext';

type MandatoryTrainingCardProps = {
  title: string;
  dueDate: string;
  totalModules: number;
  completedModules: number;
  onStart?: () => void;
};

enum CardStatus {
  normal = 'normal',
  warning = 'warning',
  urgent = 'urgent',
}

const getCardStatus = (dueDate: Date) => {
  const now: Date = new Date();
  const timeDifference = dueDate.getTime() - now.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (daysDifference < 2) {
    return CardStatus.urgent;
  } else if (daysDifference < 5) {
    return CardStatus.warning;
  } else {
    return CardStatus.normal;
  }
};

export const MandatoryTrainingCard: FC<MandatoryTrainingCardProps> = ({
  title,
  dueDate,
  totalModules,
  completedModules,
  onStart,
}: MandatoryTrainingCardProps) => {
  const dd = useMemo(() => new Date(dueDate), [dueDate]);
  const cardStatus: CardStatus = getCardStatus(dd);
  const progress = totalModules > 0 ? (completedModules / totalModules) * 100 : 0;
  const dateText =
    cardStatus === CardStatus.urgent ? (
      <NamedContentEntryText container="span" refKey="yourTraining" subKey="urgent" />
    ) : (
      <NamedContentEntryText container="span" refKey="yourTraining" subKey="dueDate" />
    );
  const buttonText = progress ? (
    <NamedContentEntryText container="span" refKey="yourTraining" subKey="resume" />
  ) : (
    <NamedContentEntryText container="span" refKey="yourTraining" subKey="startNow" />
  );
  const [langCode] = useLanguageContext();

  return (
    <TrainingCardMandatory cardStatus={cardStatus}>
      <Row>
        <IconContainer>
          <GoalFlag className="rtl-flip" />
        </IconContainer>
        <Title>{title}</Title>
        <DateLabelContainer cardStatus={cardStatus}>
          <CalendarContainer>
            <Calendar />
          </CalendarContainer>
          <DateLabel>
            {dateText}: {dd.toLocaleString(langCode, { month: 'short', day: 'numeric', year: 'numeric' })}
          </DateLabel>
        </DateLabelContainer>
      </Row>
      <Row>
        <ProgressBarContainer>
          <ProgressBar>
            <ProgressFill progress={progress} />
          </ProgressBar>
          <ProgressText>
            {completedModules} / {totalModules}
          </ProgressText>
        </ProgressBarContainer>
        <TrainingPrimaryButton onClick={onStart}>{buttonText}</TrainingPrimaryButton>
      </Row>
    </TrainingCardMandatory>
  );
};

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
`;

const ProgressBar = styled.div`
  flex-grow: 1;
  height: 8px;
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.panelBackground};
  overflow: hidden;
`;

const ProgressFill = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'progress',
})<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: linear-gradient(90deg, #758aff, #6600ff);
  transition: width 0.3s ease-in-out;
`;

const ProgressText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.normal};
`;

const DateLabelContainer = styled.label.withConfig({
  shouldForwardProp: (prop) => prop !== 'cardStatus',
})<{ cardStatus: CardStatus }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ cardStatus, theme }) =>
    cardStatus === CardStatus.urgent
      ? theme.colors.urgent
      : cardStatus === CardStatus.warning
        ? theme.colors.waningText
        : theme.colors.normal};
`;

const DateLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 500;
`;

const TrainingCardMandatory = styled(TrainingCard).withConfig({
  shouldForwardProp: (prop) => prop !== 'cardStatus',
})<{ cardStatus: CardStatus }>`
  flex-direction: column;
  align-items: unset;
  background-color: ${({ cardStatus, theme }) =>
    cardStatus === CardStatus.urgent
      ? theme.colors.urgentBackground
      : cardStatus === CardStatus.warning
        ? theme.colors.warningBackground
        : theme.colors.trainingCardBackground};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const CalendarContainer = styled.div`
  width: 16px;
  height: 16px;
`;
