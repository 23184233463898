import { useLPQuery } from '../lib/hooks/useLPQuery';
import type { LeaderboardUser } from '../lib/types/LeaderboardUser';

type TopQueryParams = {
  mode: 'top';
  type: string;
  count: number;
};

type PagedQueryParams = {
  mode: 'paged';
  type: string;
  skip: number;
  take: number;
};

type LeaderboardQueryParams = TopQueryParams | PagedQueryParams;

export type LeaderboardPagedResults = {
  total: number;
  skip: number | null;
  take: number | null;
  values: LeaderboardUser[];
};

export const useLeaderboardQuery = (params: LeaderboardQueryParams) => {
  const { type, mode } = params;

  let queryKey: (string | LeaderboardQueryParams)[];
  let endpoint: string;

  if (mode === 'top') {
    const { count } = params;
    queryKey = ['LeaderboardQuery', params];
    endpoint = `/leaderboards/${type}/top?count=${count}`;
  } else {
    const { skip, take } = params;
    queryKey = ['LeaderboardQuery', params];
    endpoint = `/leaderboards/${type}?skip=${skip}&take=${take}`;
  }

  return useLPQuery<LeaderboardUser[] | LeaderboardPagedResults>(queryKey, endpoint);
};
