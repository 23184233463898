import type { Column } from '../../Table';
import styled from 'styled-components';
import { Cell, RowContainer } from '../TableRow';

type HeaderProps<T> = {
  columns: Column<T>[];
};

export const TableHeader = <T,>({ columns }: HeaderProps<T>) => {
  return (
    <TableHeaderContent>
      <RowContainer>
        {columns.map((column: Column<T>, index: number) => (
          <HeaderCell key={index} alignment={column.alignment}>
            {column.header}
          </HeaderCell>
        ))}
      </RowContainer>
    </TableHeaderContent>
  );
};

const TableHeaderContent = styled.thead`
  display: contents;
  font-size: 12px;
`;

const HeaderCell = styled(Cell).attrs({ as: 'th' })`
  border-bottom: solid 1px ${({ theme }) => theme.colors.tableBorder};
  font-weight: 500;

  // Override border-radius styles from Cell
  &:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
