import { type FC, type PropsWithChildren, useState, type ReactNode } from 'react';
import styled from 'styled-components';
import { Expander } from '../../../Expander';
import Chevron from '../../../../assets/ChevronDown.svg';

type ExpandableSectionProps = {
  title: ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  duration?: string;
} & PropsWithChildren;

export const ExpandableSection: FC<ExpandableSectionProps> = ({
  title,
  defaultOpen = false,
  disabled = false,
  children,
  duration,
}: ExpandableSectionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const toggleOpen = () => !disabled && setIsOpen((prev) => !prev);

  return (
    <SectionContainer>
      <SectionHeader disabled={disabled} onClick={toggleOpen}>
        {!disabled && (
          <IconContainer isOpen={isOpen} duration={duration}>
            <Chevron />
          </IconContainer>
        )}
        <Title>{title}</Title>
        <Divider />
      </SectionHeader>
      <Expander isOpen={isOpen} duration={duration}>
        {children}
      </Expander>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background-color: transparent;
`;

const SectionHeader = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 500;
  white-space: nowrap;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.tableBorder};
`;

const IconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'duration',
})<{ isOpen: boolean; duration?: string }>`
  width: 16px;
  height: 16px;
  transform: rotate(${({ isOpen, theme: { rtl } }) => (isOpen ? 0 : rtl ? 90 : -90)}deg);
  transition: transform ${({ duration }) => duration ?? '500ms'} ease-in-out;
`;
