import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from '../Card';
import { Spread } from '../utility/Spread';
import { useClickOutside } from '../../lib/hooks/useOnClickOutside';
import Help from './assets/help.svg';
import SignOutIcon from './assets/sign-out.svg';
import { UserAvatarMenu } from './components/UserAvatarMenu';
import { NavLinks } from './components/NavLinks';
import { User } from './components/User';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Button } from '../Button';
import { Icon, IconType } from '../Props/Icon/Icon';
import { MenuUserAvatar } from './components/MenuUserAvatar';

export type Page = {
  name: React.ReactNode;
  path: string;
};

export type TopBarUIUser = {
  name: string | null;
  role: 'learner' | 'admin' | null;
  avatarUrl: string | null;
};

export type TopBarUIProps = {
  pages: Page[];
  user: TopBarUIUser;
  logoUrl: string;
  onSignOut: () => void;
};
export const TopBarUI: React.FC<TopBarUIProps> = ({ pages, user, logoUrl, onSignOut: onLogoff }) => {
  const [selectedMenu, setSelectedMenu] = useState<'user'>();
  const handleHide = useCallback(() => {
    setSelectedMenu(undefined);
  }, []);
  const clickOutsideRef = useClickOutside<HTMLDivElement>(handleHide);
  const menuItems = useMemo(
    () => [
      {
        element: <Help />,
        label: <NamedContentEntryText container={'span'} refKey="menuSystem" subKey="support" />,
        action: () => console.log('HELP'),
      },
      {
        element: <SignOutIcon />,
        label: <NamedContentEntryText container={'span'} refKey="menuSystem" subKey="signOut" />,
        action: () => onLogoff(),
      },
    ],
    [onLogoff],
  );
  return (
    <TopBarOuter>
      <Spread>
        <ItemGroup>
          <img src={logoUrl} alt="Logo" />
          <NavLinks pages={pages} />
        </ItemGroup>
        <ItemGroup ref={clickOutsideRef}>
          <Button
            backgroundColor={'#fff'}
            color={'#000'}
            borderColor={'#000'}
            borderRadius={'20px'}
            padding={'5px 10px'}
          >
            <ManagerViewButtonContainer>
              <Icon icon={IconType.USER_LOAD} fill={'transparent'} />
              <ManagerViewButtonText>
                Switch to <ManagerViewButtonHighlight>Manager view</ManagerViewButtonHighlight>
              </ManagerViewButtonText>
            </ManagerViewButtonContainer>
          </Button>
          <User name={user.name} role={user.role} />
          <MenuUserAvatar user={user} setSelectedMenu={setSelectedMenu} />
          <UserAvatarMenu selectedMenu={selectedMenu} user={user} menuItems={menuItems} />
        </ItemGroup>
      </Spread>
    </TopBarOuter>
  );
};

const TopBarOuter = styled(Card)`
  padding: 0 16px;
  position: relative;
`;

export const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  cursor: pointer;
`;

const ItemGroup = styled(Spread)`
  gap: 32px;
`;

const ManagerViewButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ManagerViewButtonText = styled.span`
  margin-left: 10px;
`;

const ManagerViewButtonHighlight = styled.span`
  font-weight: 500;
`;
