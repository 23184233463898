export function getTheme({ rtl }: { rtl: boolean }) {
  return {
    rtl,
    colors: {
      pageBackground: '#EEF1F9',
      panelBackground: '#FFFFFF',
      normal: '#596180',
      heading: '#292B33',
      anchor: '#5170ef',
      primary: '#4c5aff',
      primaryContrast: '#FFFFFF',
      error: '#933547',
      urgent: '#C11414',
      urgentBackground: '#FFF1F1',
      waningText: '#B1410C',
      warningBackground: '#FFF9EB',
      tableBorder: '#B6BEDC',
      userLevel: '#5601D6',
      trainingCardBackground: '#F7F8FA',
      trainingCardButton: '#DDE7FF',
    },
    fontSizes: {
      normal: '12px',
      heading: '16px',
      medium: '14px',
      large: '20px',
      extraLarge: '24px',
    },
    borderRadius: {
      small: '6px',
      normal: '8px',
      medium: '10px',
      large: '16px',
      extraLarge: '24px',
    },
    buttonSize: {
      small: '8px 16px',
      medium: '10px 20px',
      large: '12px 24px',
    },
    shadow: {
      light: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  };
}
