import { isContentEntryRefWithFallback } from '../types/ContentEntryRefWithFallback';
import { type Optional } from '../../../types/Optional';
import type { ContentEntryRef } from '../types/ContentEntryRef';
import type { ContentEntryData } from '../types/ContentEntryResponse';

export function getContentEntryQueryKey(
  ref: Optional<ContentEntryRef<ContentEntryData>, 'contentId' | 'moduleId'>,
): string[] {
  if (!isContentEntryRefWithFallback(ref)) {
    throw new Error('ContentEntryRef must have fallback');
  }
  return ref.contentId && ref.moduleId
    ? ['contentEntry', ref.moduleId, ref.contentId, ref.languageCode]
    : ['contentEntry', JSON.stringify(ref.fallbackData)];
}
