import { getContentEntriesBatcher } from '../getContentEntriesBatcher';
import { useRequestClient } from '../../../useRequestClient';
import { useMemo } from 'react';
import { fetchContentEntries } from '../fetchContentEntries';
import { memoizeUnaryLastValue } from '../../../../functional/memoizeUnaryLastValue';

export function useContentEntriesBatcher() {
  const requestClient = useRequestClient();

  const batcher = useMemo(() => {
    const fetchContentEntriesFunc = getFetchContentEntriesFunc(requestClient);
    return getContentEntriesBatcher(fetchContentEntriesFunc);
  }, [requestClient]);
  return batcher;
}
const getFetchContentEntriesFunc = memoizeUnaryLastValue(fetchContentEntries);
