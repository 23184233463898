/**
 * Capitalizes the first letter of a given string.
 *
 * @template T - A string type.
 * @param level - The string to be capitalized.
 * @returns The capitalized string.
 *
 * @remarks
 * This function ensures type safety by using TypeScript's generic types and the `Capitalize<T>` utility type.
 * It guarantees that the input and output types are consistent, preserving the original string type while
 * transforming the first character to uppercase.
 */
export function capitalize<T extends string>(level: T): Capitalize<T> {
  return (level.length === 0 ? level : level.charAt(0).toUpperCase() + level.slice(1)) as Capitalize<T>;
}
