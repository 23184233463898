import type { SnapshotLevel } from '../contentEntryRefs/contentEntryRefs';
import { useLPQuery } from '../lib/hooks/useLPQuery';

export function useCyberIQLevelsQuery() {
  return useLPQuery<CyberIqLevels>(['cyber-iq-levels'], '/cyberIq/levels');
}
export type CyberIqLevels = CyberIQLevel[];

export type CyberIQLevel = {
  id: SnapshotLevel;
  pointsThreshold: number;
  assetUrl: string;
};
