import React, { createContext, useContext, useEffect, useMemo, type PropsWithChildren } from 'react';
import { useZustandStore } from '../../../appState/StoresProvider';
import { useMeQuery } from '../../../apiHooks/useMeQuery';

type LanguageContext = readonly [language: string | undefined, setLanguage: (language: string) => void];

const defaultContextValue: LanguageContext = [
  undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
] as const;

export function createLanguageContext() {
  const LanguageContext = createContext<LanguageContext>(defaultContextValue);
  const LanguageContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const meData = useMeQuery();
    const languageCode = useZustandStore('language', (v) => v.languageCode);
    const setLanguageCode = useZustandStore('language', (v) => v.changeLanguage);
    const contextValue = useMemo(() => [languageCode, setLanguageCode] as const, [languageCode, setLanguageCode]);
    useEffect(() => {
      const lc = meData.data?.languageCode;
      const langCode = lc === null ? 'en-GB' : lc;
      if (!langCode || languageCode != null) {
        return;
      }
      setLanguageCode(langCode);
    }, [meData.data?.languageCode, languageCode, setLanguageCode]);

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
  };
  function useLanguageContext() {
    const language = useContext(LanguageContext);
    return language;
  }
  return { LanguageContextProvider, useLanguageContext };
}
