import styled from 'styled-components';
import { Button } from '../../../Button';

export const TrainingCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  margin-right: auto;
`;

export const TrainingPrimaryButton = styled(Button).attrs({ size: 'small' })`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryContrast};
  transition: all 250ms ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const TrainingSecondaryButton = styled(Button).attrs({ size: 'small' })`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.trainingCardButton};
  color: ${({ theme }) => theme.colors.primary};
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primaryContrast};
  }
`;
