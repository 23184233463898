import { useLPQuery } from '../lib/hooks/useLPQuery';

export function useCampaignsQuery(showAll: boolean) {
  return useLPQuery<EnrolledCampaignListResponse[]>(
    ['campaigns', showAll],
    `/campaigns?showAll=${showAll.toString().toLowerCase()}`,
  );
}

export type EnrolledCampaignListResponse = {
  id: string; // Guid in C#
  name: string;
  status: 'notStarted' | 'inProgress' | 'completed';
  learnerStatus: 'noAction' | 'notStarted' | 'inProgress' | 'completed' | 'failed' | 'expired';
  voluntary: boolean;
  dueDate: string;
  isStarted: boolean;
  moduleProgress: number;
  moduleTotal: number;
  campaignUrl: string;
  pointsAward?: number;
};
