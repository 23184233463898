import styled, { css } from 'styled-components';
import type { Column } from '../../Table';
import { Ellipsis } from '../../../utility/Ellipsis';
import { memo } from 'react';

type TableRowProps<T> = {
  columns: Column<T>[];
  rowData: T;
  rowColor?: string;
  onRowClick?: () => void;
  onExpandRow?: () => void;
  isExpanded?: boolean;
  roundedRows?: boolean;
  hideSeparator?: boolean;
};

export const TableRowImpl = <T,>({
  columns,
  rowData,
  rowColor,
  onRowClick,
  onExpandRow,
  isExpanded,
  roundedRows,
  hideSeparator,
}: TableRowProps<T>) => {
  return (
    <>
      <RowContainer
        onClick={() => {
          onRowClick?.();
          onExpandRow?.();
        }}
      >
        {columns.map((column: Column<T>, index: number) => {
          const cellContent = column.valueSelector(rowData);

          return (
            <Cell
              isCellClickable={(!column.columnClickDisabled && !!onRowClick) || !!onExpandRow}
              key={index}
              alignment={column.alignment}
              rowColor={rowColor}
              isExpanded={isExpanded}
              roundedRows={roundedRows}
              hideSeparator={hideSeparator}
            >
              {typeof cellContent === 'string' ? <Ellipsis>{cellContent}</Ellipsis> : cellContent}
            </Cell>
          );
        })}
      </RowContainer>
    </>
  );
};

export const TableRow = memo(TableRowImpl) as typeof TableRowImpl;

export const RowContainer = styled.tr`
  display: contents;

  &:hover {
    > td {
      background-color: ${({ theme }) => theme.colors.pageBackground};
    }
  }
`;

const cellBorderRadiusStyle = css`
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.extraLarge};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  }

  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.extraLarge};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  }
`;

export const Cell = styled.td.withConfig({
  shouldForwardProp: (prop) =>
    prop !== 'alignment' &&
    prop !== 'rowColor' &&
    prop !== 'isCellClickable' &&
    prop !== 'isExpanded' &&
    prop !== 'roundedRows' &&
    prop !== 'hideSeparator',
})<{
  alignment?: 'left' | 'center' | 'right';
  rowColor?: string;
  isCellClickable?: boolean;
  isExpanded?: boolean;
  roundedRows?: boolean;
  hideSeparator?: boolean;
}>`
  position: relative;
  min-height: 40px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 400;
  padding: 4px 8px;
  display: flex;
  justify-content: ${({ alignment }) =>
    alignment === 'center' ? 'center' : alignment === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;
  color: ${({ theme }) => theme.colors.normal};
  background-color: ${({ isExpanded, rowColor, theme }) => (isExpanded ? theme.colors.pageBackground : rowColor)};
  transition: background-color 200ms;
  cursor: ${({ isCellClickable }) => isCellClickable && 'pointer'};
  border-bottom: ${({ hideSeparator, theme }) => !hideSeparator && `solid 1px ${theme.colors.tableBorder}`};

  ${({ roundedRows }) => roundedRows && cellBorderRadiusStyle}
`;
