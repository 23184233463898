import React from 'react';
import { useSearchParam } from '../useSearchParam';

export function useShouldUseContentEntryFallbacks() {
  const contentEntryFallbacksOverride = useSearchParam('contentEntryFallbacks');
  return React.useMemo(() => {
    return contentEntryFallbacksOverride != null
      ? contentEntryFallbacksOverride === 'true'
      : import.meta.env.REACT_APP_USE_CONTENT_ENTRY_FALLBACKS === 'true';
  }, [contentEntryFallbacksOverride]);
}
