import { type FC, type KeyboardEventHandler, type PropsWithChildren, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import InfoIcon from '../../../../assets/InfoIcon.svg';
import ArrowLeft from '../../../../assets/ArrowLeft.svg';
import OTPInput from 'react-otp-input';
import { Spread } from '../../../utility/Spread';
import { Pie } from './Pie';
import { ButtonWithSpinner } from '../../../Button/ButtonWithSpinner';

type ValidateOTPCardProps = {
  email: string;
  normalizedTimeToExpiry: number;
  canResend: boolean;
  waiting: boolean;
  handleBack: () => void;
  handleResend: () => void;
  handleSubmit: (code: string) => void;
  errorText?: string | null;
  buttonDisabled?: boolean;
};

const OTP_SIZE = 6;

export const ValidateOTPCard: FC<ValidateOTPCardProps> = ({
  email,
  normalizedTimeToExpiry,
  canResend,
  waiting,
  handleBack,
  handleResend,
  handleSubmit,
  errorText,
  buttonDisabled,
}: ValidateOTPCardProps) => {
  const [otp, setOtp] = useState<string>('');

  const renderSeparator = (index: number) => {
    return index === OTP_SIZE / 2 - 1 ? <InputSeparator>-</InputSeparator> : <InputSeparator />;
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' && otp.length === OTP_SIZE) {
      handleSubmit(otp);
    }
  };

  const theme = useTheme();

  return (
    <CardWrapper onKeyDown={handleKeyDown}>
      <Spread>
        <BackButton onClick={handleBack}>
          <ArrowLeft />
          <span>Back</span>
        </BackButton>
        <Pie value={normalizedTimeToExpiry} size={16} strokeWidth={3} />
      </Spread>
      <Title>OutThink</Title>
      <SubTitle>ENTER YOUR CODE</SubTitle>
      <OTPInputContainer>
        <Description>
          Please enter below the 6-digit code sent to your email: <b>{email}</b>
        </Description>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={OTP_SIZE}
          renderSeparator={(index) => renderSeparator(index)}
          shouldAutoFocus
          skipDefaultStyles
          renderInput={(props) => <StyledInput hasValue={!!props.value} {...props} />}
        />
        {errorText && <ErrorText>{errorText}</ErrorText>}
        <ButtonWithSpinner
          type="submit"
          spinnerSize="12px"
          spinnerColor={theme.colors.primaryContrast}
          showSpinner={waiting}
          onClick={() => handleSubmit(otp)}
          disabled={waiting || otp.length !== OTP_SIZE || buttonDisabled === true}
        >
          Next
        </ButtonWithSpinner>
      </OTPInputContainer>
      {canResend && (
        <ResendLinkWrapper>
          <InfoIcon />
          <span>
            Didn’t receive the email?{' '}
            <ResendLink
              role="button"
              onClick={() => {
                setOtp('');
                handleResend();
              }}
            >
              Click to resend
            </ResendLink>
          </span>
        </ResendLinkWrapper>
      )}
    </CardWrapper>
  );
};

const InputSeparator: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  return <Separator>{children}</Separator>;
};

const Separator = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  color: ${({ theme }) => theme.colors.normal};
`;

const CardWrapper = styled.div.attrs(() => ({
  tabIndex: 0,
}))`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 80px 32px;
  width: 460px;
  gap: 40px;
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
`;

const BackButton = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 16px 1fr;
  gap: 6px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  color: ${({ theme }) => theme.colors.normal};
`;

const OTPInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const ResendLinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: 12px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const ResendLink = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const StyledInput = styled.input.withConfig({
  shouldForwardProp: (prop) => prop !== 'hasValue',
})<{ hasValue: boolean }>`
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  font-weight: 500;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  border: 2px solid ${({ theme, hasValue }) => (hasValue ? theme.colors.primary : theme.colors.normal)};
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
`;
