import { createContext, useContext, useRef, type PropsWithChildren } from 'react';
import { createStore, useStore, type StateCreator, type StoreApi } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export type StoreTypes = Record<string, unknown>;
export type Creators<T extends StoreTypes> = { [K in keyof T]: StateCreator<T[K]> };
export type Stores<T extends StoreTypes> = { [K in keyof T]: StoreApi<T[K]> };

type CreateZustandStoresContextProps<T extends StoreTypes> = {
  stateCreators: Creators<T>;
  persistKeys?: (keyof T)[];
};

type ExtractState<S> = S extends {
  getState: () => infer T;
}
  ? T
  : never;

export function createZustandStoresContext<T extends StoreTypes>({
  stateCreators,
  persistKeys: persistKeys,
}: CreateZustandStoresContextProps<T>): {
  useZustandStore: <K extends keyof T, U>(storeName: K, selector: (state: ExtractState<Stores<T>[K]>) => U) => U;
  useZustandStoreContainer: <K extends keyof T>(storeName: K) => Stores<T>[K];
  ZustandStoresProvider: React.FC<{
    children?: React.ReactNode;
  }>;
} {
  const StoresContext = createContext<Stores<T> | undefined>(undefined);
  const ZustandStoresProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const storeRef = useRef<Stores<T>>();
    if (!storeRef.current) {
      storeRef.current = Object.fromEntries(
        Object.entries(stateCreators).map(([name, stateCreator]) => {
          const creator = persistKeys?.includes(name as keyof T)
            ? persist(stateCreator, {
                name: `zustand-${name}`,
                storage: createJSONStorage(() => sessionStorage),
                // @ts-expect-error - this is a bug in the zustand types
                merge: (persistedState, currentState) => ({ ...currentState, ...persistedState }),
              })
            : stateCreator;
          const store = createStore(creator);
          // Uncomment the line below to debug the store
          //store.subscribe((s) => console.log(JSON.stringify(s, null, 2)));
          return [name, store] as const;
        }),
      ) as Stores<T>;
    }
    return <StoresContext.Provider value={storeRef.current}>{children}</StoresContext.Provider>;
  };
  function useZustandStore<K extends keyof T, U>(storeName: K, selector: (state: ExtractState<Stores<T>[K]>) => U): U {
    const store = useZustandStoreContainer<K>(storeName);
    return useStore(store, selector);
  }
  function useZustandStoreContainer<K extends keyof T>(storeName: K) {
    const stores = useContext(StoresContext);
    if (!stores) {
      throw new Error('Missing StoreProvider');
    }
    return stores[storeName];
  }
  return { useZustandStore, useZustandStoreContainer, ZustandStoresProvider };
}
