import { UserMenu } from './UserMenu';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { LanguageSelector } from '../../LanguageSelector';
import { useTransitionVisibility } from '../../../lib/hooks/useTransitionVisibility';

export type UserAvatarMenuProps = {
  selectedMenu: string | undefined;
  user: {
    name: string | null;
    role: 'learner' | 'admin' | null;
    avatarUrl: string | null;
  };
  menuItems: {
    element: ReactNode;
    label: ReactNode;
    action: () => void;
  }[];
};

export const UserAvatarMenu: React.FC<UserAvatarMenuProps> = ({ selectedMenu, user, menuItems }) => {
  const visible = selectedMenu === 'user';
  const { ref, isMounted } = useTransitionVisibility<HTMLDivElement>(visible);
  return (
    isMounted && (
      <UserMenu $isHidden={!visible} ref={ref}>
        {user.name && (
          <UserMenuSection>
            <h3>{user.name}</h3>
          </UserMenuSection>
        )}
        <UserMenuSection>
          <MenuSectionItems>
            {menuItems.map(({ element, label, action }, idx) => (
              <MenuSectionItem key={`menuSectItem${idx}`} onClick={action}>
                {element}
                <span>{label}</span>
              </MenuSectionItem>
            ))}
          </MenuSectionItems>
        </UserMenuSection>
        <UserMenuSection>
          <LanguageSelector />
        </UserMenuSection>
      </UserMenu>
    )
  );
};

const MenuSectionItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const MenuSectionItem = styled.button`
  all: unset;
  cursor: pointer;
  display: grid;
  grid-template-columns: 16px max-content;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.heading};
  width: max-content;
`;

const UserMenuSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.normal)};
  }
  &:not(:first-child) {
    padding-top: 8px;
  }
`;
