import { type InputHTMLAttributes } from 'react';
import styled from 'styled-components';

type TextInputProps = {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorText?: string | null;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextInput = ({
  label,
  placeholder = '',
  value,
  onChange,
  disabled = false,
  errorText,
  ...props
}: TextInputProps) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input type="text" placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} {...props} />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.heading};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  border: 1px solid ${({ theme }) => theme.colors.normal};
  border-radius: ${({ theme }) => theme.borderRadius.normal};

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
