import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import type { KyInstance } from 'ky';
import { useZustandStore, useZustandStoreContainer } from '../../../appState/StoresProvider';
import ky from 'ky';
import { useQueryClient } from '@tanstack/react-query';

export function createRequestClientContext() {
  const RequestClientContext = createContext<KyInstance | undefined>(undefined);
  const RequestClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const tenantId = useZustandStore('auth', (v) => v.tenantId);
    const authStore = useZustandStoreContainer('auth');
    const queryClient = useQueryClient();
    const kyInstance = useMemo(
      () =>
        ky.extend({
          hooks: {
            beforeRequest: [
              (request) => {
                const state = authStore.getState();
                if (state.authenticationState !== 'loggedIn') {
                  throw new Error('no auth available. this is unexpected');
                }
                const expiresAt = new Date(state.signInDetails.expiresAt).getTime();
                if (expiresAt < Date.now()) {
                  queryClient.clear();
                  authStore.getState().signOut();
                  throw new Error('session expired');
                }
                request.headers.set('Authorization', `OTP ${state.signInDetails.sessionId}`);
                if (tenantId) {
                  request.headers.set('OT-Customer-Id', tenantId);
                }
              },
            ],
          },
        }),
      [authStore, tenantId, queryClient],
    );
    return <RequestClientContext.Provider value={kyInstance}>{children}</RequestClientContext.Provider>;
  };
  function useRequestClient() {
    const requestClient = useContext(RequestClientContext);
    // if (!requestClient) {
    //   throw new Error('Missing RequestClientProvider');
    // }
    return requestClient;
  }
  return { RequestClientProvider, useRequestClient };
}
