import type { FC } from 'react';
import styled from 'styled-components';
import { type CyberIQBadge, useCyberIQBadgesQuery } from '../../../apiHooks/useCyberIQBadgesQuery';

type BadgeListProps = {
  icons: string[];
};

export const BadgeList: FC<BadgeListProps> = ({ icons }) => {
  const { data: cyberIQBadges } = useCyberIQBadgesQuery();

  if (!cyberIQBadges) {
    return null;
  }

  const filteredBadges: CyberIQBadge[] = icons
    .map((id: string) => cyberIQBadges.find((badge: CyberIQBadge): boolean => badge.id === id))
    .filter((item: CyberIQBadge | undefined): item is CyberIQBadge => item !== undefined);

  return (
    <Container>
      {filteredBadges.map((badge) => (
        <img key={badge.id} src={badge.assetUrl} alt={`Badge ${badge.id}`} width={30} height={33} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
