import type { TextBlockData } from '../../lib/hooks/contentEntry/types/ContentEntryResponse';
import { type ContentEntryRefWithFallback } from '../../lib/hooks/contentEntry/types/ContentEntryRefWithFallback';
import type { TextBlockRefLight } from '../contentEntryRefs';

export function toContentEntryRefs<
  T extends Record<string, TextBlockRefLight>,
  R = {
    [K in keyof T]: T[K] extends { nested: true }
      ? Omit<NestedContentEntryTextBlockRefWithFallback<T[K]['propSubKeyIndexes']>, 'languageCode'>
      : Omit<SimpleContentEntryTextBlockRefWithFallback, 'languageCode'>;
  },
>(simpleTextBlockRefs: T): R {
  return Object.fromEntries(
    Object.entries(simpleTextBlockRefs).map(([key, value]) => {
      return [
        key,
        {
          nested: value.nested,
          contentId: value.contentId,
          moduleId: '0cb49e30-eb05-45fc-9967-2ad7ef99e9a3',
          type: 'textBlock',
          fallbackData: {
            type: 'textBlock',
            text: value.fallback,
          },
          propSubKeyIndexes: value.nested ? value.propSubKeyIndexes : undefined,
        },
      ] as const;
    }),
  ) as R;
}

export type SimpleContentEntryTextBlockRefWithFallback = ContentEntryRefWithFallback<TextBlockData> & { nested: false };
export type NestedContentEntryTextBlockRefWithFallback<PD extends Record<string, number>> =
  ContentEntryRefWithFallback<TextBlockData> & {
    nested: true;
    propSubKeyIndexes: PD;
  };
export type ContentEntryTextBlockRefWithFallback<PD extends Record<string, number>> =
  | SimpleContentEntryTextBlockRefWithFallback
  | NestedContentEntryTextBlockRefWithFallback<PD>;
