import { NamedContentEntryText } from '../../components/ContentEntryText/NamedContentEntryText';
import { RobotAvatar } from '../../components/Props/RobotAvatar/RobotAvatar';
import type { ReactNode } from 'react';
import type { ContentEntryRefsKey } from '../../contentEntryRefs/contentEntryRefs';
import { capitalize } from '../string/capitalize';

export enum UserLevels {
  vulnerable = 'vulnerable',
  determined = 'determined',
  solid = 'solid',
  armored = 'armored',
  unbreakable = 'unbreakable',
}

export type UserLevelDetailsType = Record<
  UserLevels,
  {
    level: ReactNode;
    name: ReactNode;
    introText: ReactNode;
    description: ReactNode;
    image: ReactNode;
  }
>;

export const UserLevelDetails = Object.fromEntries(
  Object.values(UserLevels).map((level) => {
    const lvl = capitalize(level);
    const levelKey = `securitySnapshotLevels${lvl}` satisfies ContentEntryRefsKey;
    return [
      level,
      {
        level: <NamedContentEntryText container="span" refKey={levelKey} subKey="levelN" />,
        name: <NamedContentEntryText container="span" refKey={levelKey} subKey="levelName" />,
        introText: <NamedContentEntryText container="span" refKey={levelKey} subKey="bodyHeading" />,
        description: <NamedContentEntryText container="span" refKey={levelKey} subKey="bodyMain" asHtml />,
        image: <RobotAvatar levelId={lvl} />,
      },
    ] as const;
  }),
) as UserLevelDetailsType;
