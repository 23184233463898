import { mauve, violet } from '@radix-ui/colors';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import styled from 'styled-components';
import { useLanguagesWithAlpha2Codes } from './hooks/useLanguagesWithAlpha2Codes';
import { forwardRef, useCallback, type FC, type PropsWithChildren } from 'react';
import { Flag } from '../Flag';
import { transparentize } from 'polished';
import { useLanguageContext } from '../../lib/hooks/useLanguageContext';

export const LanguageSelector = () => {
  const languagesWithAlpha2Codes = useLanguagesWithAlpha2Codes();
  const [selectedLanguageCode, setSelectedLanguageCode] = useLanguageContext();
  const portalContainer = document.getElementById('radix-ui-portal');
  const formatLanguageLabel = useCallback(({ name }: { name: string; code: string }) => name, []);

  return (
    selectedLanguageCode && (
      <Select.Root value={selectedLanguageCode} onValueChange={(e) => setSelectedLanguageCode(e)}>
        <SelectTrigger aria-label="select language">
          <ValueContainer>
            <Select.Value title={selectedLanguageCode} />
          </ValueContainer>
        </SelectTrigger>
        <Select.Portal container={portalContainer}>
          <SelectContent position="popper">
            <SelectScrollUpButton>
              <ChevronUpIcon />
            </SelectScrollUpButton>
            <SelectViewport>
              {languagesWithAlpha2Codes.map(({ alpha2Code, code, name }) => (
                <SelectItem key={code} value={code}>
                  <LanguageSelectItem alpha2Code={alpha2Code} name={formatLanguageLabel({ name, code })} />
                </SelectItem>
              ))}
            </SelectViewport>
            <SelectScrollDownButton>
              <ChevronDownIcon />
            </SelectScrollDownButton>
          </SelectContent>
        </Select.Portal>
      </Select.Root>
    )
  );
};

const LanguageName = styled.span`
  white-space: nowrap;
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

const SelectTrigger = styled(Select.SelectTrigger)`
  all: unset;
  color: ${({
    theme: {
      colors: { heading },
    },
  }) => heading};
  font-size: inherit;
  cursor: pointer;
`;

const ValueContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.7em;
  font-family: 'Poppins', sans-serif;
  width: 100%;

  > span {
    width: 100%;
  }
`;

const SelectContent = styled(Select.Content)`
  max-height: var(--radix-select-content-available-height);
  padding: 5px 0;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid ${({ theme }) => transparentize(0.7, theme.colors.normal)};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  backdrop-filter: blur(15px);
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`;

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: violet.violet11,
  cursor: 'default',
};

const SelectScrollUpButton = styled(Select.ScrollUpButton)(scrollButtonStyles);

const SelectScrollDownButton = styled(Select.ScrollDownButton)(scrollButtonStyles);

const SelectViewport = styled(Select.Viewport)({
  padding: 5,
});

const SelectItem = forwardRef<HTMLDivElement, PropsWithChildren<Select.SelectItemProps>>(
  function SelectItemF(props, forwardedRef) {
    const { children, ...rest } = props;
    return (
      <StyledItem {...rest} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
        <StyledItemIndicator>
          <CheckIcon />
        </StyledItemIndicator>
      </StyledItem>
    );
  },
);

const StyledItem = styled(Select.Item)`
  line-height: 1;
  color: ${({ theme }) => theme.colors.heading};
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 24px;
  position: relative;
  user-select: none;
  font-family: 'Poppins', sans-serif;

  &[data-disabled] {
    color: ${mauve.mauve8};
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: none;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primaryContrast};
  }
`;

const StyledItemIndicator = styled(Select.ItemIndicator)({
  position: 'absolute',
  left: 0,
  width: 20,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LanguageSelectItem: FC<{
  alpha2Code: string;
  name?: string;
}> = ({ alpha2Code, name }) => {
  return (
    <LSContainer>
      <F>
        {/* Firefox does weird things when measuring img with svg source, so contain it */}
        <Flg alpha2Code={alpha2Code} />
      </F>
      {name ? <LanguageName title={name}>{name}</LanguageName> : null}
    </LSContainer>
  );
};

const LSContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: 250px;
`;

const F = styled.span`
  display: inline-block;
  width: 18px;
  height: 12px;
  flex-shrink: 0;
`;

const Flg = styled(Flag)`
  height: 100%;
  width: 100%;
  margin-right: 10px;
  display: block;
  transform-origin: top left;
`;
