import { useLPQuery } from '../lib/hooks/useLPQuery';

export function useSecuritySnapshotQuery() {
  return useLPQuery<SecuritySnapshot>(['security-snapshot'], '/me/securitySnapshot');
}
type SecuritySnapshot = {
  cyberIqPoints: number;
  learnerId: string;
  levelId: string;
  badges: string[];
};
