import React, { type FC } from 'react';
import styled from 'styled-components';
import { getContrast, math, tint } from 'polished';

export const AvatarInitials: FC<
  React.PropsWithChildren<{ name: string; id: string; size?: string; className?: string; onClick?: () => void }>
> = ({ name, id, size = '22px', className, onClick }) => {
  const { initials, color, textColor } = React.useMemo(() => {
    const allInitials = name
      .trim()
      .split(/\s+/)
      .map((n) => n.charAt(0).toUpperCase())
      .filter((n) => n.match(/\p{Letter}/u));
    const charSum = id.split('').reduce((a, b) => a + b.charCodeAt(0), 0);
    const colorIndex = charSum % colors.length;
    const color = colors[colorIndex];
    if (color == null) {
      throw new Error('color is null');
    }
    const black = '#000';
    const white = '#fff';
    const textColor = getTextColor(color, black, white);
    const initials =
      allInitials.length === 0
        ? '?'
        : allInitials.length > 1 && allInitials[0]
          ? allInitials[0] + allInitials[allInitials.length - 1]
          : allInitials[0];
    return { initials, color, textColor };
  }, [name, id]);

  return (
    <Container size={size} color={color} textColor={textColor} className={className} onClick={onClick}>
      {initials}
    </Container>
  );
};

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#34495e',
  '#bfbf40',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e74c3c',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
];

function getTextColor(bgColor: string, black: string, white: string) {
  const whiteContrast = getContrast(bgColor, white);
  const blackContrast = getContrast(bgColor, black);
  return whiteContrast > blackContrast ? white : black;
}

const Container = styled.div<{ size: string; color: string; textColor: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => props.textColor};
  background: linear-gradient(45deg, ${(props) => props.color}, ${(props) => tint(0.35, props.color)} 75%);
  font-size: ${(props) => math(`${props.size} * 0.42`)};
  user-select: none;
  cursor: pointer;
`;
