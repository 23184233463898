import styled from 'styled-components';
import type { FC } from 'react';
import { Icon, IconType } from '../Props/Icon/Icon';
import { Card } from '../Card';
import { Coin } from '../Props';
import { ProgressArrow } from '../Props/ProgressArrow/ProgressArrow';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

type ScoreItem = {
  title: string;
  description: string;
  icon: IconType;
  coins?: number;
  performanceChange?: number;
};

export const ScoreBreakdown: FC = () => {
  const trainings: ScoreItem[] = [
    {
      title: 'Complete all training',
      description:
        'You have one overdue mandatory cybersecurity training. Completing this training on time is crucial to keeping both you and the company safe and compliant.',
      icon: IconType.CLIPBOARD_CHECK,
      coins: -300,
    },
    {
      title: 'Maintain high engagement',
      description:
        'Your engagement is up last 30 days with  2%. Great! Keep asking questions, completing modules and sharing tips with your colleagues This helps the company and you to stay safe',
      icon: IconType.DASH_LOADING_HEARTH,
      performanceChange: 2,
      coins: 150,
    },
    {
      title: 'Build your knowledge',
      description:
        'Your training knowledge has declined over last 30 days and is lower compared to average.In future sessions make sure to revisit the training materials before you take the knowledge assessment questions.',
      icon: IconType.BRAIN,
      performanceChange: -2,
      coins: -100,
    },
    {
      title: 'Report attacks',
      description:
        'In the past 30 days, you spotted and reported 3 out of 5 phishing simulations. Great job! Keep it up to earn more points—each identified phish earns you 250 CyberIQ points.',
      icon: IconType.SHIELD_SEARCH,
      performanceChange: 3,
      coins: 250,
    },
    {
      title: 'Avoid clicking',
      description: 'There were no simulations issued in last 30 days. Stay vigilant and earn points by reporting them!',
      icon: IconType.MOUSE_CURSOR,
      performanceChange: 0,
    },
  ];

  return (
    <Container>
      <Card title={<NamedContentEntryText container={'h2'} refKey="sectionTitles" subKey="scoreBreakdown" />}>
        {trainings.map((item: ScoreItem) => (
          <ScoreItem key={item.title}>
            <ScoreItemIcon>
              <Icon icon={item.icon} />
            </ScoreItemIcon>
            <ScoreItemContent>
              <ScoreItemHeader>
                <ScoreItemHeaderTitle>{item.title}</ScoreItemHeaderTitle>
                {item.performanceChange !== undefined ? <ProgressArrow value={item.performanceChange} /> : null}
              </ScoreItemHeader>
              <div>{item.description}</div>
            </ScoreItemContent>
            <ScoreItemCoin>{item.coins ? <Coin value={item.coins} /> : null}</ScoreItemCoin>
          </ScoreItem>
        ))}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const ScoreItem = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ScoreItemIcon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  background: #f7f8fa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ScoreItemContent = styled.div`
  width: 100%;
  margin: 0 20px;
`;

const ScoreItemHeader = styled.div`
  display: flex;
`;

const ScoreItemHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
`;

const ScoreItemCoin = styled.div`
  display: flex;
  align-items: baseline;
`;
