import { create, windowScheduler } from '@yornaath/batshit';
import { type ContentEntryRef } from '../types/ContentEntryRef';
import { type ContentEntryData, type ContentEntryResponse } from '../types/ContentEntryResponse';
import { dedupeAndFetchContentEntries } from './dedupeAndFetchContentEntries';
import type { FetchContentEntriesFunc } from '../types/FetchContentEntriesFunc';
import { memoizeUnaryLastValue } from '../../../functional/memoizeUnaryLastValue';

const getContentEntriesBatcherImpl = (fetchContentEntries: FetchContentEntriesFunc) => {
  const dedupeAndFetchCEs = dedupeAndFetchContentEntries(fetchContentEntries);
  return create<
    (ContentEntryResponse<ContentEntryData> | undefined)[],
    ContentEntryRef<ContentEntryData> | undefined,
    ContentEntryResponse<ContentEntryData> | undefined
  >({
    fetcher: (
      refs: (ContentEntryRef<ContentEntryData> | undefined)[],
    ): Promise<(ContentEntryResponse<ContentEntryData> | undefined)[]> => dedupeAndFetchCEs(refs),
    resolver: (entries, ref) =>
      entries.find((entry) => entry?.ref?.contentId === ref?.contentId && entry?.ref?.moduleId === ref?.moduleId),
    scheduler: windowScheduler(10),
  });
};

export const getContentEntriesBatcher = memoizeUnaryLastValue(getContentEntriesBatcherImpl);
