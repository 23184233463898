import styled from 'styled-components';
import type { FC } from 'react';
import { RobotAvatar } from '../Props/RobotAvatar/RobotAvatar';
import { SplitButton } from '../Button';
import {
  SteppingProgressBar,
  type SteppingProgressBarStep,
} from '../ProgressBar/SteppingProgressBar/SteppingProgressBar';
import { Icon, IconType } from '../Props/Icon/Icon';
import { useSecuritySnapshotQuery } from '../../apiHooks/useSecuritySnapshotQuery';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { Card } from '../Card';
import { useCyberIQLevelsQuery } from '../../apiHooks/useCyberIQLevelsQuery';
import type { SnapshotLevel } from '../../contentEntryRefs/contentEntryRefs';
import { BadgeList } from '../Props/BadgeList/BadgeList';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

export const SecuritySnapshotUI: FC = () => {
  let name = '';
  let currentIQPoints = '';
  let currentThresholdIQPoints = '';
  let currentLevel = 0;
  // ID of the level
  let currentLevelLabel: SnapshotLevel | undefined;
  let stepperSteps: SteppingProgressBarStep[] = [];
  let badges: string[] = [];

  const securitySnapshotQuery = useSecuritySnapshotQuery();
  const cyberIqLevelsQuery = useCyberIQLevelsQuery();
  const meQuery = useMeQuery();

  if (securitySnapshotQuery.data && meQuery.data && cyberIqLevelsQuery.data) {
    const securitySnapshotData = securitySnapshotQuery.data;
    const cyberIqLevelsData = cyberIqLevelsQuery.data;
    const meData = meQuery.data;

    const currentLevelIndex: number = cyberIqLevelsData.findIndex((level) => {
      return level.id === securitySnapshotData.levelId;
    });

    const dataLevel = cyberIqLevelsData[currentLevelIndex];

    if (dataLevel) {
      currentLevelLabel = dataLevel.id;
    }

    const nextDataLevel = cyberIqLevelsData[currentLevelIndex + 1];

    if (nextDataLevel) {
      currentThresholdIQPoints = nextDataLevel.pointsThreshold.toLocaleString();
    }

    if (meData.name) {
      name = meData.name;
    }

    currentIQPoints = securitySnapshotData.cyberIqPoints.toLocaleString();
    badges = securitySnapshotData.badges;

    currentLevel = currentLevelIndex;
    stepperSteps = cyberIqLevelsData.map((level, index) => {
      return {
        value: index,
        label: level.id,
      };
    });
  }
  const securitySnapshotLevelKey = currentLevelLabel
    ? (`securitySnapshotLevels${currentLevelLabel}` as const)
    : undefined;

  return (
    <Card title={<NamedContentEntryText container={'h2'} refKey="sectionTitles" subKey="securitySnapshot" />}>
      <CardContainer>
        <CardTopSection>
          <LeftCardSection>
            <RobotAvatar levelId={currentLevelLabel} />
          </LeftCardSection>
          <RightCardSection>
            <TopSection>
              <UsernameField>{name}</UsernameField>
              <SplitButton
                leftElement={
                  securitySnapshotLevelKey && (
                    <NamedContentEntryText container={'span'} refKey={securitySnapshotLevelKey} subKey="levelN" />
                  )
                }
                rightElement={
                  securitySnapshotLevelKey && (
                    <NamedContentEntryText container={'span'} refKey={securitySnapshotLevelKey} subKey="levelName" />
                  )
                }
              />
            </TopSection>
            <DescriptionField>
              {securitySnapshotLevelKey ? (
                <>
                  <NamedContentEntryText
                    container={'div'}
                    refKey={securitySnapshotLevelKey}
                    subKey="bodyHeading"
                    asHtml
                  />
                  <NamedContentEntryText container={'div'} refKey={securitySnapshotLevelKey} subKey="bodyMain" asHtml />
                </>
              ) : null}
            </DescriptionField>
            <StatsField>
              <BadgeList icons={badges} />
              <IQPointsContainer>
                <IQPointsField>
                  <Icon icon={IconType.COIN} />
                  <IQPointsLabel>
                    {<NamedContentEntryText container={'span'} refKey="securitySnapshot" subKey="cyberiqPoints" />}:
                  </IQPointsLabel>
                </IQPointsField>
                <IQPointsValue>
                  <b>{currentIQPoints}</b> / {currentThresholdIQPoints}
                </IQPointsValue>
              </IQPointsContainer>
            </StatsField>
          </RightCardSection>
        </CardTopSection>
        <CardBottomSection>
          <SteppingProgressBar steps={stepperSteps} value={currentLevel} />
        </CardBottomSection>
      </CardContainer>
    </Card>
  );
};

const CardContainer = styled.div`
  background-color: #eef1f9;
  border-radius: 12px;
`;

const CardTopSection = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid #ccc;
`;

const CardBottomSection = styled.div`
  padding: 10px 20px 20px 20px;
`;

const UsernameField = styled.h2`
  margin: auto auto auto 0;
  font-weight: 700;
  font-size: 20px;
`;

const LeftCardSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightCardSection = styled.div`
  flex: 1;
`;

const TopSection = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const DescriptionField = styled.div`
  margin-bottom: 15px;
  line-height: 1.75;
  font-size: 14px;
`;

const StatsField = styled.div`
  display: flex;
`;

const IQPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  font-size: 14px;
`;

const IQPointsField = styled.div`
  display: flex;
  font-weight: 500;
  margin-bottom: 5px;
`;

const IQPointsLabel = styled.label`
  margin-left: 10px;
`;

const IQPointsValue = styled.div`
  text-align: right;
`;
