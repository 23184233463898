import { useMemo } from 'react';
import type { Column } from '../../Table/Table';
import { UserLevels } from '../../../lib/enums/userLevels';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';
import type { LabelledToggleOption } from '../../Toggle/LabelledToggle/LabelledToggle';
import type { LeaderboardUser } from '../../../lib/types/LeaderboardUser';
import avatarUrl from '../../TopBar/assets/user.png';
import { UserRank } from '../components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type LeaderboardWidgetData = {
  placeholderData: LeaderboardUser[];
  columns: Column<LeaderboardUser>[];
  scopeFilterLabels: LabelledToggleOption[];
  dateFilterLabels: LabelledToggleOption[];
};

export const useLeaderboardWidgetData = (): LeaderboardWidgetData => {
  return useMemo(
    () => ({
      placeholderData: PLACEHOLDER_DATA,
      scopeFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myDepartment" />,
          value: 'department',
          selected: true,
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myCompany" />,
          value: 'company',
        },
      ],
      dateFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="allTime" />,
          selected: true,
          value: 'all',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="thisMonth" />,
          value: 'month',
        },
      ],
      columns: [
        {
          header: 'Rank',
          valueSelector: (user: LeaderboardUser) => <UserRank rank={user.rank} previousRank={user.previousRank} />,
          alignment: 'left',
          width: '100px',
        },
        {
          header: 'Name',
          valueSelector: (user: LeaderboardUser) => (
            <UserName>
              <UserImg src={user.avatarUrl} />
              {user.name}
            </UserName>
          ),
          alignment: 'left',
          width: '1fr',
        },
        {
          header: 'Points',
          valueSelector: (user: LeaderboardUser) => (
            <UserPoints>
              {formatNumberWithCommas(user.cyberIqPoints)} <Icon width={20} height={20} icon={IconType.COIN} />
            </UserPoints>
          ),
          alignment: 'right',
          width: '100px',
        },
      ],
    }),
    [],
  );
};

const PLACEHOLDER_DATA: LeaderboardUser[] = [
  {
    name: 'Robert Fox',
    rank: 1,
    previousRank: 1,
    levelId: UserLevels.unbreakable,
    cyberIqPoints: 4950,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Devon Lane',
    rank: 2,
    previousRank: 3,
    levelId: UserLevels.determined,
    cyberIqPoints: 4840,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Mark Frost',
    rank: 3,
    previousRank: 2,
    levelId: UserLevels.solid,
    cyberIqPoints: 3800,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Benny Adams',
    rank: 4,
    previousRank: 4,
    levelId: UserLevels.vulnerable,
    cyberIqPoints: 3200,
    avatarUrl: avatarUrl,
  },
];

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
