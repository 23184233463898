import type { FC } from 'react';
import CoinSVG from './assets/Coin.svg';
import RulerOfMassesSVG from './assets/RulerOfMasses.svg';
import TheTalkinatorSVG from './assets/TheTalkinator.svg';
import GlobeTrotterSVG from './assets/GlobeTrotter.svg';
import PhantomPosterSVG from './assets/PhantomPoster.svg';
import ProgressArrowUpSVG from './assets/ProgressArrowUp.svg';
import ProgressArrowDownSVG from './assets/ProgressArrowDown.svg';
import AINeuralShareSVG from './assets/AINeuralShare.svg';
import BrainSVG from './assets/Brain.svg';
import ClipboardCheckSVG from './assets/ClipboardCheck.svg';
import CPUProcessorRefreshSVG from './assets/CPUProcessorRefresh.svg';
import DashLoadingHearthSVG from './assets/DashLoadingHearth.svg';
import MalwareSVG from './assets/Malware.svg';
import MouseCursorSVG from './assets/MouseCursor.svg';
import ShieldSearchSVG from './assets/ShieldSearch.svg';
import WebPageSVG from './assets/WebPage.svg';
import UserLoadSVG from './assets/UserLoad.svg';
import { type SVGRSvg } from '../../../lib/types/SVGRSvg';

type IconProps = {
  icon: string;
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
};

export const Icon: FC<IconProps> = ({
  icon,
  width = '24',
  height = '24',
  fill = 'currentColor',
  className = '',
}: IconProps) => {
  const SelectedIcon = IconMapper[icon];

  if (!SelectedIcon) {
    console.error(`Icon "${icon}" not found.`);
    return null;
  }

  return <SelectedIcon width={width} height={height} fill={fill} className={className} />;
};

export enum IconType {
  COIN = 'Coin',
  BADGE_RULER_OF_MASSES = 'RulerOfMasses',
  BADGE_THE_TALKINATOR = 'TheTalkinator',
  BADGE_GLOBE_TROTTER = 'GlobeTrotter',
  BADGE_PHANTOM_POSTER = 'PhantomPoster',
  PROGRESS_ARROW_UP = 'ProgressArrowUp',
  PROGRESS_ARROW_DOWN = 'ProgressArrowDown',
  AI_NEURAL_SHARE = 'AINeuralShare',
  BRAIN = 'Brain',
  CLIPBOARD_CHECK = 'ClipboardCheck',
  CPU_PROCESSOR_REFRESH = 'CPUProcessorRefresh',
  DASH_LOADING_HEARTH = 'DashLoadingHearth',
  MALWARE = 'Malware',
  MOUSE_CURSOR = 'MouseCursor',
  SHIELD_SEARCH = 'ShieldSearch',
  WEB_PAGE = 'WebPage',
  USER_LOAD = 'UserLoad',
}

const IconMapper: Record<string, SVGRSvg> = {
  [IconType.COIN]: CoinSVG,
  [IconType.BADGE_RULER_OF_MASSES]: RulerOfMassesSVG,
  [IconType.BADGE_THE_TALKINATOR]: TheTalkinatorSVG,
  [IconType.BADGE_GLOBE_TROTTER]: GlobeTrotterSVG,
  [IconType.BADGE_PHANTOM_POSTER]: PhantomPosterSVG,
  [IconType.PROGRESS_ARROW_UP]: ProgressArrowUpSVG,
  [IconType.PROGRESS_ARROW_DOWN]: ProgressArrowDownSVG,
  [IconType.AI_NEURAL_SHARE]: AINeuralShareSVG,
  [IconType.BRAIN]: BrainSVG,
  [IconType.CLIPBOARD_CHECK]: ClipboardCheckSVG,
  [IconType.CPU_PROCESSOR_REFRESH]: CPUProcessorRefreshSVG,
  [IconType.DASH_LOADING_HEARTH]: DashLoadingHearthSVG,
  [IconType.MALWARE]: MalwareSVG,
  [IconType.MOUSE_CURSOR]: MouseCursorSVG,
  [IconType.SHIELD_SEARCH]: ShieldSearchSVG,
  [IconType.WEB_PAGE]: WebPageSVG,
  [IconType.USER_LOAD]: UserLoadSVG,
};
