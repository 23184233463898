import { useEffect, useMemo } from 'react';
import { useZustandStore } from '../../../appState/StoresProvider';
import { useCountdown } from '../../../lib/hooks/useCountdown';
import type { OTPRequestInfo } from '../../../appState/stateCreators/auth';

export function useOtpRequestExpiryHandling() {
  const resetOtpRequestInfo = useZustandStore('auth', (v) => v.resetOtpRequestInfo);
  const otpRequestInfo = useZustandStore('auth', (v) =>
    v.authenticationState === 'otpRequested' ? v.otpRequestInfo : undefined,
  );

  const expiryInfo = useMemo(() => (otpRequestInfo ? getExpiryInfo(otpRequestInfo) : undefined), [otpRequestInfo]);

  useEffect(() => {
    if (expiryInfo != null && expiryInfo.timeToExpiry < 0) {
      console.log('OTP expired');
      resetOtpRequestInfo();
    }
  }, [expiryInfo, resetOtpRequestInfo]);

  const countdownValue = useCountdown(expiryInfo?.timeToExpiry ?? -1, () => {
    console.log('OTP expired');
    resetOtpRequestInfo();
  });
  const canResend = useMemo(
    () => (!expiryInfo ? false : countdownValue < expiryInfo.totalWaitTime - 20000),
    [countdownValue, expiryInfo],
  );
  return expiryInfo == null || expiryInfo.timeToExpiry < 0
    ? { normalizedTimeToExpiry: -1, canResend: false }
    : { normalizedTimeToExpiry: countdownValue / expiryInfo.totalWaitTime, canResend };
}
function getExpiryInfo(otpRequestInfo: OTPRequestInfo): { timeToExpiry: number; totalWaitTime: number } | undefined {
  const timeToExpiry = new Date(otpRequestInfo.expiry).getTime() - Date.now();
  const totalWaitTime = new Date(otpRequestInfo.expiry).getTime() - new Date(otpRequestInfo.receivedAt).getTime();
  return {
    timeToExpiry,
    totalWaitTime,
  };
}
