import { type FC } from 'react';
import { UserImg, type TopBarUIUser } from '../TopBarUI';
import { AvatarInitials } from './AvatarInitials';
import { useCallback } from 'react';

export const MenuUserAvatar: FC<MenuUserAvatarProps> = ({ user, setSelectedMenu }) => {
  const clickHandler = useCallback(() => {
    setSelectedMenu((v) => (v === 'user' ? undefined : 'user'));
  }, [setSelectedMenu]);
  return user.avatarUrl ? (
    <UserImg src={user.avatarUrl} alt="User Avatar" onClick={clickHandler} />
  ) : (
    <AvatarInitials name={user.name || ''} id={user.name ?? ''} onClick={clickHandler} size="40px" />
  );
};

export type MenuUserAvatarProps = {
  user: TopBarUIUser;
  setSelectedMenu: (cb: (v: 'user' | undefined) => 'user' | undefined) => void;
};
