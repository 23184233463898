import { useMemo } from 'react';
import type { Column } from '../../Table/Table';
import { UserRank } from '../components';
import { UserLevelDetails, UserLevels } from '../../../lib/enums/userLevels';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';
import type { LabelledToggleOption } from '../../Toggle/LabelledToggle/LabelledToggle';
import { BadgeList } from '../../Props/BadgeList/BadgeList';
import { Tooltip } from '../../Tooltip';
import { LevelTooltip } from '../components';
import type { LeaderboardUser } from '../../../lib/types/LeaderboardUser';
import avatarUrl from '../../TopBar/assets/user.png';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type LeaderboardData = {
  placeholderData: LeaderboardUser[];
  columns: Column<LeaderboardUser>[];
  scopeFilterLabels: LabelledToggleOption[];
  dateFilterLabels: LabelledToggleOption[];
};

export const useLeaderboardData = (): LeaderboardData => {
  return useMemo(
    () => ({
      placeholderData: PLACEHOLDER_DATA,
      scopeFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myDepartment" />,
          value: 'department',
          selected: true,
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myCompany" />,
          value: 'company',
        },
      ],
      dateFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="allTime" />,
          selected: true,
          value: 'all',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="thisMonth" />,
          value: 'month',
        },
      ],
      columns: [
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="rank" />,
          valueSelector: (user: LeaderboardUser) => <UserRank rank={user.rank} previousRank={user.previousRank} />,
          alignment: 'left',
          width: '1fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="name" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserName>
              <UserImg src={user.avatarUrl} />
              {user.name}
            </UserName>
          ),
          alignment: 'left',
          width: '2fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="badges" />,
          valueSelector: () => (
            <BadgeList
              icons={[
                IconType.BADGE_GLOBE_TROTTER,
                IconType.BADGE_PHANTOM_POSTER,
                IconType.BADGE_RULER_OF_MASSES,
                IconType.BADGE_THE_TALKINATOR,
              ]}
            />
          ),
          alignment: 'left',
          width: '6fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="level" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserLevelWrapper>
              <Tooltip offset={15} content={<LevelTooltip tooltipDetails={UserLevelDetails[user.levelId]} />}>
                <UserLevel>
                  {UserLevelDetails[user.levelId]?.level} <b>{UserLevelDetails[user.levelId]?.name}</b>
                </UserLevel>
              </Tooltip>
            </UserLevelWrapper>
          ),
          alignment: 'left',
          width: '2fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="points" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserPoints>
              {formatNumberWithCommas(user.cyberIqPoints)} <Icon width={20} height={20} icon={IconType.COIN} />
            </UserPoints>
          ),
          alignment: 'right',
          width: '1fr',
        },
      ],
    }),
    [],
  );
};

const PLACEHOLDER_DATA: LeaderboardUser[] = [
  {
    name: 'Robert Fox',
    rank: 1,
    previousRank: 1,
    levelId: UserLevels.unbreakable,
    cyberIqPoints: 4950,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Devon Lane',
    rank: 2,
    previousRank: 3,
    levelId: UserLevels.determined,
    cyberIqPoints: 4840,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Mark Frost',
    rank: 3,
    previousRank: 2,
    levelId: UserLevels.solid,
    cyberIqPoints: 3800,
    avatarUrl: avatarUrl,
  },
  {
    name: 'Benny Adams',
    rank: 4,
    previousRank: 4,
    levelId: UserLevels.vulnerable,
    cyberIqPoints: 3200,
    avatarUrl: avatarUrl,
  },
];

const UserLevelWrapper = styled.div`
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;

const UserLevel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.userLevel};
`;

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
