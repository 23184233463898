import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type ExpanderProps = {
  isOpen: boolean;
  duration?: string;
  className?: string;
} & PropsWithChildren;

export const Expander: FC<ExpanderProps> = ({ isOpen, duration, className, children }: ExpanderProps) => {
  return (
    <ExpanderContainer open={isOpen} duration={duration}>
      <Inner className={className}>{children}</Inner>
    </ExpanderContainer>
  );
};

const ExpanderContainer = styled.div
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'duration',
  })
  .attrs<{ open?: boolean; duration?: string }>((props) => ({
    style: {
      gridTemplateRows: props.open ? '1fr' : '0fr',
    },
  }))<{ open?: boolean; duration?: string }>`
  display: grid;
  transition: grid-template-rows ${(props) => props.duration ?? '500ms'} ease-in-out;
`;
const Inner = styled.div`
  overflow: hidden;
`;
