import { type ChangeEvent, type FC, type FormEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import InfoIcon from '../../../../assets/InfoIcon.svg';
import { TextInput } from '../../../TextInput';
import { ButtonWithSpinner } from '../../../Button/ButtonWithSpinner';

type LoginComponentProps = {
  onSubmit: (email: string) => void;
  guidanceText?: string | null;
  logoUrl?: string | null;
  waiting: boolean;
  errorText?: string | null;
  isEnabled?: boolean;
};

const DEFAULT_GUIDANCE_TEXT = "If you can't login, contact your manager or a colleague with Administrator rights.";

export const LoginForm: FC<LoginComponentProps> = ({
  onSubmit,
  guidanceText,
  logoUrl,
  errorText,
  waiting,
  isEnabled,
}: LoginComponentProps) => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(email);
  };
  const theme = useTheme();

  return (
    <CardContainer>
      {isEnabled ? (
        <FormWrapper>
          {logoUrl && <Logo src={logoUrl} />}
          <SubTitle>CYBERIQ SIGN IN</SubTitle>
          <Form onSubmit={handleSubmit}>
            <TextInput
              label="Email"
              placeholder="Enter your email"
              onChange={handleInputChange}
              errorText={errorText}
            />
            <ButtonWithSpinner
              type="submit"
              spinnerSize="12px"
              spinnerColor={theme.colors.primaryContrast}
              showSpinner={waiting}
              disabled={waiting}
            >
              Next
            </ButtonWithSpinner>
          </Form>
          <HelperText>
            <InfoIcon />
            {guidanceText ?? DEFAULT_GUIDANCE_TEXT}
          </HelperText>
        </FormWrapper>
      ) : (
        <DisabledLoginContainer>
          <DisabledLoginTitle>OutThink</DisabledLoginTitle>
          <DisabledLoginSubtitle>CYBERIQ PORTAL</DisabledLoginSubtitle>
          <div>
            <DisabledLoginParagraph>
              The CyberIQ Portal is a groundbreaking gamified cybersecurity learning experience for both employees and
              line managers.
            </DisabledLoginParagraph>
            <DisabledLoginParagraph>Contact your company administrator to enable access.</DisabledLoginParagraph>
          </div>
        </DisabledLoginContainer>
      )}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  padding: 80px 32px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  width: 350px;
  height: 100%;

  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const DisabledLoginContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  display: block;
  height: 100%;
  max-height: 50px;
  width: min-content;
  margin: 0;
  object-fit: contain;
`;

const DisabledLoginTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
  margin: 0 0 36px 0;
`;

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  color: ${({ theme }) => theme.colors.normal};
`;

const DisabledLoginSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  color: ${({ theme }) => theme.colors.normal};
  margin: 0 0 20px 0;
`;

const DisabledLoginParagraph = styled.p`
  margin: 0 0 20px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HelperText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: 12px;
`;
