import { useQuery } from '@tanstack/react-query';
import { useRequestClient } from '../lib/hooks/useRequestClient';

export function useLanguagesQuery() {
  const kyInstance = useRequestClient();
  if (!kyInstance) {
    throw new Error('kyInstance is not defined');
  }
  return useQuery<LanguagesQueryResponse>({
    queryKey: ['languages'],
    queryFn: async () => {
      const response = await kyInstance.get(`https://outthink-api-dev.azure-api.net/content/languages`, {
        headers: { TrainingDeliveryPath: 'DEMO' },
      });
      return response.json();
    },
    staleTime: 86_400_000, // 1 day
  });
}
type Language = {
  code: string;
  name: string;
};

type LanguagesQueryResponse = {
  languages: Language[];
  defaultLanguage: Language;
};
