import { type FC, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { LoginForm } from './components/LoginForm';
import { useZustandStore } from '../../appState/StoresProvider';
import { type LoginThemeConfig, LoginThemes, useLoginTheme } from '../../appState/hooks/useLoginTheme';
import { Spinner } from '../Spinner/Spinner';

export const Login: FC = () => {
  const requestOTP = useZustandStore('auth', (v) => v.requestOTP);
  const waiting = useZustandStore('auth', (v) => v.isFetching);
  const loginSettings = useZustandStore('auth', (v) => 'loginSettings' in v && v.loginSettings);

  const [error, setError] = useState<string | undefined>(undefined);
  const loginTheme: LoginThemeConfig = useLoginTheme();

  const handleLogin = (email: string) => {
    requestOTP(email).then((status) => {
      if (!status.success) {
        setError(status.error);
      }
    });
  };

  const theme = useTheme();

  return (
    <LoginContainer loginTheme={loginTheme}>
      <LoginContent>
        {loginSettings ? (
          <LoginForm
            guidanceText={loginSettings.signInGuidanceText}
            onSubmit={handleLogin}
            logoUrl={loginSettings.logoUrl}
            errorText={error}
            waiting={waiting}
            isEnabled={loginSettings.portalEnabled}
          />
        ) : (
          <Spinner size={'32px'} color={theme.colors.primary} />
        )}
        {loginTheme.type === LoginThemes.splitScreen ? <Image src={loginTheme.defaultSplitScreenURL} /> : null}
      </LoginContent>
    </LoginContainer>
  );
};

const LoginContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'loginTheme',
})<{ loginTheme: LoginThemeConfig }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: ${({ loginTheme, theme }) =>
    loginTheme.type === LoginThemes.fullBackground
      ? `url(${loginTheme.defaultFullBackgroundURL})`
      : theme.colors.pageBackground};
  background-size: cover;
`;

const LoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 500px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const Image = styled.img`
  display: block;
  border-radius: 0 ${({ theme }) => theme.borderRadius.normal} ${({ theme }) => theme.borderRadius.normal} 0;
  width: 350px;
  height: 100%;

  @media (max-width: 768px) {
    max-height: 350px;
  }
`;
