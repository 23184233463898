import { useLPQuery } from '../lib/hooks/useLPQuery';

export const useLatestActivitiesQuery = () => {
  return useLPQuery<GetLatestActivitiesResponse>(['latest-activities'], '/activities/latest');
};

export type GetLatestActivitiesResponse = {
  learnerId: string;
  learnerActivities: LearnerActivity[];
};

export type LearnerActivity = {
  actionScoreType: string;
  createdTime: Date;
  points?: number;
};
