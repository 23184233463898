import { useEffect, useState } from 'react';
import { _lastOrDefault } from 'ts-iterable-functions';

export function useResizeObserver(elem: Element | null | undefined): DOMRectReadOnly | undefined {
  const [domRect, setDomRect] = useState<DOMRectReadOnly>();
  useEffect(() => {
    if (elem) {
      const initialRect = elem.getBoundingClientRect();
      setDomRect(initialRect);
      let mounted = true;
      const obs = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const dr = _lastOrDefault(entries.filter((e) => e.target === elem))?.contentRect;
        if (dr && mounted) {
          setDomRect(dr);
        }
      });
      obs.observe(elem);
      return () => {
        mounted = false;
        obs.unobserve(elem);
        obs.disconnect();
      };
    }
    return undefined;
  }, [elem]);
  return domRect;
}
export type DOMRectReadOnly = {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
};
