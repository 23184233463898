import type { FC } from 'react';
import { TrainingWidget } from './TrainingWidget';
import styled from 'styled-components';
import { ActivityOverviewWidget } from './ActivityOverviewWidget';

export const Activity: FC = () => {
  return (
    <Layout>
      <Column>
        <TrainingWidget />
      </Column>
      <Column>
        <ActivityOverviewWidget />
      </Column>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  min-height: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
