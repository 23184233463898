import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { GlobalWrapper } from './GlobalWrapper';
import { ZustandStoresProvider } from '../appState/StoresProvider';
import { fontFaces } from './fontFaces';
import { useFontFaces } from '../lib/hooks/useFontFaces';
import { useRouter } from '../lib/hooks/useRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RequestClientProvider } from '../lib/hooks/useRequestClient';
import { LanguageContextProvider } from '../lib/hooks/useLanguageContext';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  useFontFaces(fontFaces, true);
  return (
    <ZustandStoresProvider>
      <QueryClientProvider client={queryClient}>
        <RequestClientProvider>
          <LanguageContextProvider>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            <GlobalWrapper>
              <AppRouterProvider />
            </GlobalWrapper>
          </LanguageContextProvider>
        </RequestClientProvider>
      </QueryClientProvider>
    </ZustandStoresProvider>
  );
};

const AppRouterProvider: React.FC = () => {
  const router = useRouter();
  return <RouterProvider router={router} />;
};
