import styled from 'styled-components';
import type { FC } from 'react';
import type { SnapshotLevel } from '../../../contentEntryRefs/contentEntryRefs';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import { useIsRTLLayout } from '../../../appState/hooks/useIsRTLLayout';

export type SteppingProgressBarStep = {
  label: SnapshotLevel;
  value: number;
};

type SteppingProgressBarProps = {
  steps: SteppingProgressBarStep[] | undefined;
  value: number | undefined;
};

export const SteppingProgressBar: FC<SteppingProgressBarProps> = ({ steps, value }) => {
  // const stepRefs = steps?.map((step) => `securitySnapshotLevels${step.label}` as const);
  // const stepEntries = useContentEntryRefs(stepRefs ?? []);
  const labelsAndPositions = steps?.map((step, i) => ({
    labelName: `securitySnapshotLevels${step.label}` as const,
    label: step.label,
    posPct: (100 * i) / (steps.length - 1),
  }));
  const { achievedStep, achievedStepThreshold } = steps?.reduce(
    (acc, step) =>
      value != null && value >= step.value
        ? { achievedStep: acc.achievedStep + 1, achievedStepThreshold: step.value }
        : acc,
    { achievedStep: -1, achievedStepThreshold: 0 },
  ) ?? { achievedStep: -1, achievedStepThreshold: 0 };
  const nextStep: SteppingProgressBarStep | undefined =
    steps && achievedStep + 1 < steps.length ? steps[achievedStep + 1] : undefined;
  const nextStepThreshold = nextStep ? nextStep.value - achievedStepThreshold : undefined;
  const nextStepProgress = value != null ? value - achievedStepThreshold : undefined;
  const nextStepPercentage =
    nextStepThreshold != null && nextStepProgress != null && steps
      ? nextStepProgress / nextStepThreshold / (steps.length - 1)
      : 0;
  const progressPercentage = steps ? (achievedStep / (steps.length - 1) + nextStepPercentage) * 100 : 0;
  const isRTL = useIsRTLLayout();
  return steps && labelsAndPositions ? (
    <Container>
      <Labels count={steps.length}>
        {labelsAndPositions.map(({ label, posPct, labelName }, index) => (
          <LabelText
            key={label}
            style={{ [isRTL ? 'right' : 'left']: `${posPct}%` }}
            $isActive={index === achievedStep} // Note: $isActive for styled-components syntax
          >
            <NamedContentEntryText refKey={labelName} container={'span'} subKey="levelName" />
          </LabelText>
        ))}
      </Labels>
      <Meter value={progressPercentage} />
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Labels = styled.div<{ count: number }>`
  width: 100%;
  position: relative;
  height: 20px;
  margin-bottom: 8px;
`;

const LabelText = styled.span<{ $isActive: boolean }>`
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  display: block;
  transform: translateX(-50%);
  color: ${({ $isActive }) => ($isActive ? '#000' : '#888')};
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};

  &:first-child {
    transform: translateX(0);
  }
  &:last-child {
    transform: translateX(-100%);
  }
`;

const Meter = styled.div<{ value: number }>`
  width: 100%;
  background-color: #0002;
  height: 10px;
  position: relative;
  border-radius: 6px;

  &::before {
    content: ' ';
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(to right, #758aff, #6600ff);
    width: ${({ value }) => value}%;
    position: absolute;
    transition: width 0.3s ease;
  }
`;
